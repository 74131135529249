import React, { useEffect, useState, useCallback } from 'react';
import Footer from '../../components/footer';
import { useTranslation } from 'react-i18next';
import Header from '../../menu/header';
import MoonPayModal from './MoonPayModal';
import TransakModal from './TransakModal';
import Arrow from '../../../../assets/images/arrow.png';
import WireTransfer from '../../../../assets/images/wire-transfer.png';
import CardPayment from '../../../../assets/images/card-payment.png';
import USDC from "../../../../assets/images/USDC.webp"
import AVAX from "../../../../assets/images/AVAX.png"
import MoonPay from "../../../../assets/images/moonpay-1.png"
import { Select, MenuItem, Dialog, Typography } from '@material-ui/core';
import { StyledHeader } from '../../../MarkePlace/Styles';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import { login } from '../../../../utils/functions/authenticate';
import * as setActions from '../../../../store/actions';
import ModalError from '../../../../components/Servererror/modalError';
import { useSelector, useDispatch } from 'react-redux';
import * as selectors from '../../../../store/selectors';
import { useHistory } from 'react-router-dom';
import useStyles from "../../../../styles/components/Footer/"
import axios from 'axios';
import { EPATHS } from '../../../../routes/paths.enum';
import _ from 'lodash';
import { useAppKit } from '@reown/appkit/react';

function CryptoExchange() {
  const {t}=useTranslation();
  const [openMoonPayModal, setOpenMoonPayModal] = useState(false);
  const [amount, setAmount] = useState(0);
  const [payment, setPayment] = useState(0);
  const [fiat, setFiat] = useState(0);
  const [tokenType, setTokenType] = useState(0);
  const [priceTransak, setPriceTransak] = useState(0);
  const [priceMoonpay, setPriceMoonpay] = useState(0);
  const theme = 'HOME';
  const TRANSAK_KEY = process.env.REACT_APP_TRANSAK_KEY;
  const MOONPAY_KEY = process.env.REACT_APP_MOONPAY_KEY;

  const signedUser = useSelector(selectors.user);
  const dispatch = useDispatch();
  const [serverError, setServerError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const classes=useStyles();
  const stroageUser = localStorage.getItem('_user') === null ? '{}' : localStorage.getItem('_user') + '';
  const isAuthenticated = localStorage.getItem('_user') 
    && JSON.parse(stroageUser).isVerified ? true : false;

  const { open } = useAppKit();

  useEffect(() => {
    if(!isAuthenticated) setShowModal(true);
  }, [isAuthenticated]);

  useEffect(() => {
    if(amount) debouncedFetchQuote(amount, fiat, tokenType, payment);
  }, [amount, fiat, tokenType, payment]);

  const debouncedFetchQuote = useCallback(
    _.debounce((amountValue, fiatValue, tokenTypeValue, paymentValue) => {
      fetchQuote(amountValue, fiatValue, tokenTypeValue, paymentValue); // Replace this with your desired action
    }, 300), // Wait for 300 milliseconds
    []
);

  const fetchQuote = (fiatAmount, fiatValue, tokenTypeValue, paymentValue) => {
    axios({
      method: 'GET',
      url: 'https://api-stg.transak.com/api/v1/pricing/public/quotes',
      params: {
        partnerApiKey: TRANSAK_KEY,
        fiatCurrency: fiatValue === 0 ? 'USD' : 'EUR',
        cryptoCurrency: tokenTypeValue === 0 ? 'USDC' : 'AVAX',
        isBuyOrSell: 'BUY',
        network: 'avaxcchain',
        paymentMethod: paymentValue === 0 ? 'credit_debit_card' : (
          fiatValue === 0 ? 'pm_us_wire_bank_transfer' : 'sepa_bank_transfer'
        ),
        fiatAmount: fiatAmount
      },
    })
    .then(data => {
      setPriceTransak(data.data.response.cryptoAmount);
    }).catch(err => console.log(err));

    axios({
      method: 'GET',
      url: `https://api.moonpay.com/v3/currencies/${tokenTypeValue === 0 ? 'usdc_cchain' : 'avax_cchain'}/buy_quote`,
      params: {
        apiKey: MOONPAY_KEY,
        baseCurrencyCode: fiatValue === 0 ? 'usd' : 'eur',
        paymentMethod: paymentValue === 0 ? 'credit_debit_card' : (
          fiatValue === 0 ? 'gbp_bank_transfer' : 'sepa_bank_transfer'
        ),
        baseCurrencyAmount: fiatAmount
      },
    })
    .then(data => {
      setPriceMoonpay(data.data.quoteCurrencyAmount);
    }).catch(err => console.log(err));
  }

  const handleAuth = async () => {
    open();
    setShowModal(false);
  }

  return (
    <div>
      <StyledHeader theme={theme} />
      <Header className="HOME"/>
      {signedUser.id ? <section>
        <div><p style={{
          textAlign: 'center',
          fontSize: 30,
          fontWeight: 700,
          color: '#a706aa',
          marginTop: 30
        }}>Get USDC & AVAX</p></div>
        <div>
          <p style={{
            textAlign: 'center',
            color: 'black',
            fontWeight: 600,
            fontSize: 20
          }}><u>1 - Enter an Amount</u></p>
        </div>
        <div style={{display: 'flex', width: 500, margin: 'auto', alignItems: 'center'}}>
          <input
            type="number"
            name="item_price_usdt"
            className="form-control"
            value={amount}
            onChange={(event) => setAmount(event.target.value)}
          />
          <Select 
            value={fiat}
            onChange={(e) => setFiat(e.target.value)}
            // style={styles.variableValue}
            variant="outlined"
            defaultValue=""
            style={{
              height: 37,
              marginTop: -15
            }}
          >
            <MenuItem value={0}>USD</MenuItem>
            <MenuItem value={1}>EUR</MenuItem>
          </Select>
        </div>
        <div style={{textAlign: 'center', margin: 20}}>
          <img src={Arrow} alt="" style={{width: 25}} />
        </div>

        <div>
          <p style={{
            textAlign: 'center',
            color: 'black',
            fontWeight: 600,
            fontSize: 20
          }}><u>2 - Select Payment Method</u></p>
        </div>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <div style={{display: 'grid', margin: 15}}>
            <img src={CardPayment} alt="" style={{cursor: 'pointer', width: 215}} onClick={() => setPayment(0)} />
            <input type="radio"
              name='card_payment'
              onChange={(e) => setPayment(0) }
              style={{color: '#a706aa', height: 20, cursor: 'pointer', marginTop: 15}}
              checked={payment === 0 ? true : false}
            />
          </div>
          <div style={{display: 'grid', margin: 15}}>
            <img src={WireTransfer} alt="" style={{cursor: 'pointer', width: 215}} onClick={() => setPayment(1)} />
            <input
              type="radio"
              name='wire_payment'
              onChange={(e) => setPayment(1) }
              style={{color: '#a706aa', height: 20, cursor: 'pointer', marginTop: 15}}
              checked={payment === 1 ? true : false}
            />
          </div>
        </div>
        <div style={{textAlign: 'center', margin: 20}}>
          <img src={Arrow} alt="" style={{width: 25}} />
        </div>

        <div>
          <p style={{
            textAlign: 'center',
            color: 'black',
            fontWeight: 600,
            fontSize: 20
          }}><u>3 - Choose a Token</u></p>
        </div>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <div style={{display: 'grid', margin: 15}}>
            <img src={USDC} alt="" style={{width: 100, cursor: 'pointer'}} onClick={() => setTokenType(0)} />
            <p style={{textAlign: 'center', color: 'black', marginTop: 13}}>USDC</p>
            <input
              type="radio"
              name='usdc'
              onChange={(e) => setTokenType(0) }
              checked={tokenType === 0 ? true : false}
              style={{color: '#a706aa', height: 20, cursor: 'pointer', marginTop: -12}}
            />
          </div>
          <div style={{display: 'grid', margin: 15}}>
            <img src={AVAX} alt="" style={{width: 130, marginTop: -18, cursor: 'pointer'}} onClick={() => setTokenType(1)} />
            <p style={{textAlign: 'center', color: 'black'}}>AVAX</p>
            <input
              type="radio"
              name='avax'
              onChange={(e) => setTokenType(1) }
              checked={tokenType === 1 ? true : false}
              style={{color: '#a706aa', height: 20, cursor: 'pointer', marginTop: -15}}
            />
          </div>
        </div>
        <div style={{textAlign: 'center', margin: 20}}>
          <img src={Arrow} alt="" style={{width: 25}} />
        </div>

        <div>
          <p style={{
            textAlign: 'center',
            color: 'black',
            fontWeight: 600,
            fontSize: 20
          }}><u>4 - Select a Provider</u></p>
        </div>
        <div style={{width: 600, margin: 'auto'}}>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around', border: '4px solid #a706aa', borderRadius: 20, margin: 15, cursor: 'pointer'}} onClick={() => setOpenMoonPayModal(true)}>
            <img src={MoonPay} style={{height: 70}} alt="" />
            <div>
              <p style={{margin: 5, color: 'black', fontWeight: 600, fontSize: 20}}>{priceMoonpay}{tokenType === 0 ? ' USDC' : ' AVAX'}</p>
              <p>{`≃ ${amount} ${fiat === 0 ? 'USD' : 'EUR'}`}</p>
            </div>
          </div>
          {!(fiat === 0 && payment === 1) && <TransakModal amount={amount} priceTransak={priceTransak} tokenType={tokenType} fiat={fiat} payment={payment} />}
          <p style={{color: '#a706aa', textAlign: 'center', fontSize: 15, fontWeight: 600, cursor: 'pointer'}} onClick={() => fetchQuote(amount, fiat, tokenType, payment)}><i>Refresh Quotes</i></p>
        </div>
      </section> : <section style={{height: 600}}></section>}

      <div className='container'>
        <div className="row">    
          {openMoonPayModal && <MoonPayModal showModal={openMoonPayModal} setShowModal={setOpenMoonPayModal} amount={amount} priceTransak={priceTransak} tokenType={tokenType} fiat={fiat} payment={payment}/>}
        </div>
      </div>
      <Dialog
        open={showModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className='alert-modal'
      >
        <div className={classes.rootModal}>
        <div className={classes.topBlock}>
          <CheckCircleOutlinedIcon className={classes.icon} style={{width: 80, height: 80}} />
        </div>  
        <div className={classes.bottomBlock}>
          <Typography className={classes.title}>
            {t("You need to connect your wallet first!")}
          </Typography>
          <div className={classes.buttonBlock}>
          <button
            className="btn-main"
            onClick={()=>{
              setShowModal(false);
              history.push({pathname: EPATHS.HOME});
            }}
          >
            {t("Close")} 
          </button>
          <button
            className="btn-main"
            onClick={() => { handleAuth(); }}
          >
            {t("Connect")}
          </button>
          </div>
        </div>
        </div>
        { serverError && <ModalError open={serverError} onClose={() => {setServerError(false); setShowModal(false);}}/>}   
      </Dialog>
      <Footer />
    </div>
  )
}

export default CryptoExchange