import React, { useRef  } from 'react';

const ImageUpload = ({selectedImages, setSelectedImages, isEnabled, previewFiles, setPreviewFiles, directory, setPreviewRemovedFiles}) => {
  const fileInputRef = useRef(null);
  const server3dntechURL = process.env.REACT_APP_3DNTECH_SERVER;

  const handleButtonClick = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    const newImages = files.filter((file) => {
      // Check if the file name already exists in the selectedImages
      const fileName = file.name;
      return !selectedImages.some((img) => img.name === fileName);
    });

    // Update preview files and selected images only with new files
    if (newImages.length > 0) {
      setPreviewFiles((prevFiles) => prevFiles.concat(newImages));
      const images = newImages.map((file) => URL.createObjectURL(file));
      setSelectedImages((prevImages) => prevImages.concat(images));
    }

    event.target.value = ''; // Reset input value to allow re-uploading same file
  };

  const removeImage = (image) => {
    if(image.exists) {
      setPreviewRemovedFiles((prevFiles) => prevFiles.concat(image));
    }
    setSelectedImages(selectedImages.filter((img) => img !== image));
  };

  return (
    <div>
      <button className='btn-main' style={{cursor: 'pointer'}} disabled={!isEnabled} onClick={handleButtonClick}>Upload Preview Images</button>
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        multiple
        style={{display: 'none'}}
        onChange={handleImageChange}
      />
      <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px' }}>
        {selectedImages.map((image, index) => (
          <div key={index} style={{ position: 'relative', margin: '5px' }}>
            <img
              src={image.exists ? server3dntechURL + '/' + directory + '/' + image.name : image}
              alt={`Selected preview ${index}`}
              style={{ width: '200px', height: '100px', objectFit: 'cover', padding: 5 }}
            />
            <button
              onClick={(e) => {
                e.preventDefault();
                removeImage(image);
              }}
              style={{
                position: 'absolute',
                top: '5px',
                right: '5px',
                background: 'red',
                color: 'white',
                border: 'none',
                borderRadius: '50%',
                cursor: 'pointer',
              }}
            >
              &times;
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageUpload;