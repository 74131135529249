import React, { useEffect } from 'react';
import GetStartSection from '../../components/Home/getStartSection';
import ExploreSection from '../../components/Home/exploreSection';
import CreateSection from '../../components/Home/createSection';
import TradeSection from '../../components/Home/tradeSection';
// import Footer from "../../components/Footer"
import Footer from '../../pages/MarkePlace/components/footer';
import { StyledHeader } from '../MarkePlace/Styles';
import Header from '../MarkePlace/menu/header';
import * as actions from '../../store/actions/thunks';
import { useDispatch } from 'react-redux';

//SWITCH VARIABLE FOR PAGE STYLE
const theme = 'HOME'; //LIGHT, GREY, RETRO

function HomePage() {
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0,0);
    if(!localStorage.getItem('_user')) {
      const emptyUser = {};
      dispatch(actions.setUser(emptyUser));
    }
  }, [])
  return <div>
    <StyledHeader theme={theme} />
    <Header className="HOME"/>
    <GetStartSection/>
    <ExploreSection/>
    <CreateSection/>
    <TradeSection/>
    <Footer/>
  </div>;
}

export default HomePage;
