import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Button, Typography } from '@material-ui/core';
import useStyles from "../../styles/components/Servererror/modalError";

function PassCheckModal({open, onClose, onSubmit}) {
    const classes=useStyles();

  return (
    <React.Fragment>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className='alert-modal'
      >
        <div className={classes.root}>
          <div className={classes.topBlock}>
              <HighlightOffIcon className={classes.icon} onClick={() => {
                onClose();
              }} />
          </div>  
          <div className={classes.inGameBlock}>
              <Typography className={classes.igwTitle}>
                {"You already created an account with this email address inside Earniverse."}
              </Typography>
              <Typography className={classes.igwTitle}>
                {"To link your current wallet to your account, please confirm that you have entered the same email and password used in Earniverse during registration."}
              </Typography>
              <div className={classes.inputBlock}>
                <Button 
                  style={{margin: 'auto'}}
                  color="primary"
                  variant="contained"
                  onClick={() => onSubmit()}
                >
                  {'It\'s Good!'}
                </Button>
                <Button 
                  style={{margin: 'auto'}}
                  color="primary"
                  variant="contained"
                  onClick={() => onClose()}
                >
                  {'Cancel'}
                </Button>
              </div>
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  )
}

export default PassCheckModal;