import React from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import BACKGROUND from "../../assets/images/Image_Frame1.jpg"

import useStyles from "../../styles/components/GetStarted/playEarniverseSection"

function PlayEarniverseSection() {
  const classes=useStyles();

  return <div className={classes.root}> 
    <img src={BACKGROUND} alt={BACKGROUND} className={classes.img} />
    <div className={classes.blockText}>
      <Typography variant='h1' className={classes.title}>PLAY EARNIVERSE</Typography>
      <p className={classes.subtitle}>Download Earniverse Launcher on your computer, and follow instructions for easy install.</p>
    </div>
  </div>;
}

export default PlayEarniverseSection;
