import React from 'react';
import { Button, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { EPATHS } from '../../routes/paths.enum';
import useStyles from "../../styles/components/Servererror/modalError";
import { useHistory } from 'react-router-dom';

function ModalRegister({open, onClose, showResend}) {
  const classes=useStyles();
  const history = useHistory();
    // const [open, setOpen] = useState(true);
  return (
    <Dialog
    open={open && window.location.pathname !== EPATHS.CHANGE_EMAIL}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    className='alert-modal'
  >
      <div className={classes.root}>
        <div className={classes.topBlock}>
            <HighlightOffIcon className={classes.icon} onClick={() => onClose()} />
        </div>  
        <div className={classes.bottomBlock}>
            <Typography className={classes.title} style={{margin: 15, fontSize: 16}}>
            Your account has not been verified. Please click on the button below to start your email verification process
            </Typography>
            <Button
              color="primary"
              variant="contained"
              onClick={showResend}
            >
              Verify my Email
            </Button>
            <a onClick={() =>  window.location.href = EPATHS.CHANGE_EMAIL} className={classes.modalLink}><u>Change My Email</u></a>
        </div>
      </div>
  </Dialog>
  )
}

export default ModalRegister;