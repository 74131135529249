import React, { memo, useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay-ts';
import { ReactNotifications } from 'react-notifications-component';
import { Store } from 'react-notifications-component';

import 'react-notifications-component/dist/theme.css';

import Clock from '../../components/Clock';
import Footer from '../../components/footer';
import { createGlobalStyle } from 'styled-components';
import Web3 from "web3";
import * as actions from '../../../../store/actions/thunks';
/*import Checkout from "../components/Checkout";
import Checkoutbid from "../components/Checkoutbid";*/
import api from '../../../../core/api';
import { useParams, useHistory } from 'react-router';
import Header from '../../menu/header';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { Switch, TextField, Typography, Button } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import Nft3dCard from '../../../../components/Cards/nft3DCard';
import { EPATHS } from '../../../../routes/paths.enum';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Dialog from '@material-ui/core/Dialog';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from "../../../../styles/components/Servererror/modalError";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import VariableItem from '../../components/VariableItem';
import ImageUpload from '../../components/ImageUpload';
import * as setActions from '../../../../store/actions';
import * as selectors from '../../../../store/selectors';

import * as CategoryService from "../../../../services/CategoryService";
import * as DataService from "../../../../services/DataService";
import * as AssetService from "../../../../services/AssetService";
import * as DesignerService from "../../../../services/DesignerService";
import * as TagService from "../../../../services/TagService";
import * as UtilityService from "../../../../services/UtilityService";
import * as VideoService from "../../../../services/VideoService";
import * as UploadService from "../../../../services/UploadService";
import Notification from "../../../../utils/functions/notification";
import authHeader from "../../../../services/auth-header";
import TagsDropDown from '../../components/TagsDropDown';
import { debounce } from 'lodash';
import UtilitiesDropDown from '../../components/UtilitiesDropDown';

const GreenSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: green[500],
    },
    '&$checked + $track': {
      backgroundColor: green[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

const GlobalStyles = createGlobalStyle `
  header#myHeader.navbar.white {
    background: #fff;
    border-bottom: solid 1px #dddddd;
  }
  header#myHeader.navbar .search #quick_search{
    color: #20005e;
    background: rgba(255, 255, 255, .1);
    }
    header#myHeader.navbar.white .btn, .navbar.white a{
        color: #20005e;
    }
    header#myHeader.navbar.sticky.white .btn, .navbar.sticky.white a{
        color: #20005e;
    }
    header#myHeader .dropdown-toggle::after{
        color: #20005e;
    }
  .mr40{
    margin-right: 40px;
  }
  .mr15{
    margin-right: 15px;
  }
  .btn2{
    background: #f6f6f6;
    color: #8364E2 !important;
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #111;
    }
    .item-dropdown .dropdown a{
      color: #111 !important;
    }
  }
`;

const companyWallet = process.env.REACT_APP_COMPANY_WALLET_ADDRESS;

const EditItem = () => {
  const { nftId } = useParams();
  const [isActive, setActive] = useState(false);
  const classes=useStyles();

  const { t } = useTranslation();

  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [title, setTitle] = useState("");
  const [isForBid, setIsForBid] = useState();
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState();
  // const [priceRate, setPriceRate] = useState(0);
  const [isListed, setIsListed] = useState(false);
  const [numberOfItem, setNumberOfItem] = useState();
  const [limitDate, setLimitDate] = useState();
  const [designer, setDesigner] = useState('');
  const [designers, setDesigners] = useState([]);
  const [company, setCompany] = useState(null);
  const [numberOfItemError, setNumberOfItemError] = useState(false);
  const [treeData, setTreeData] = useState([]);
  const [newSubCategory, setNewSubCategory] = useState('');
  const [selectedNode, setSelectedNode] = useState([]);
  const [selectedNodeName, setSelectedNodeName] = useState();
  const [selectedTree, setSelectedTree] = useState([]);
  const [isUpdate, setUpdate] = useState(false);
  const [showAddNewModal, setShowAddNewModal] = useState(false);
  const [previewImg, setPreviewImg] = useState();
  const [previewFormData, setPreviewFormData] = useState();
  const [variables, setVariables] = useState([]);
  const [filteredVariables, setFilteredVariables] = useState([]);
  const [variableJson, setVariableJson] = useState([{
    value: "0",
    variable: 'Level'
  }]);
  const [currentVariableItem, setCurrentVariableItem] = useState();
  const [continents, setContinents] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [previewFiles, setPreviewFiles] = useState([]);
  const [previewRemovedFiles, setPreviewRemovedFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);

  const inputRef = useRef(null);
  const signedUser = useSelector(selectors.user);

  const history = useHistory();

  const dispatch = useDispatch();
  let nft = useSelector(state=>state.NFT.assetDetail.data);

  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState('');
  const [tagIds, setTagIds] = useState([]);

  const [utilities, setUtilities] = useState([]);
  const [newUtility, setNewUtility] = useState('');
  const [utilityIds, setUtilityIds] = useState([]);

  const inputVideoRef = useRef(null);
  const [mediaId, setMediaId] = useState();
  const [videoId, setVideoId] = useState();

  const checkTitleValidation = (value) => {
    if(value) {
      setErrorMessage(false);
      try {
        DataService.fetchAsset(value).then(res => {
          if(res) {
            setErrorMessage('Asset title already exists.');
          }
          else {
            setErrorMessage(false);
          }
        }).catch(error => {     
          let msg = (typeof error === 'object') ? error.message : error;
          setErrorMessage(msg);
        });
      }
      catch (error) {   
        let msg = (typeof error === 'object') ? error.message : error;
        setErrorMessage(msg);
      }
    }
  }

  const [debounceCheckTitleValidation] = useState(() => debounce(checkTitleValidation, 1000));

  useEffect(() => {
    if(signedUser.id) {
      CategoryService.fetchCategory({exclude: 1}).then((res => {
        setCategories(res);
        generateTreeData(res);
      }));      
      
      AssetService.fetchAssetVariable().then((res => {
        setVariables(res.filter((item) => item.variable !== 'Level'));
      }));

      TagService.fetchTags().then((res) => {
        setTags(res);
      });

      UtilityService.fetchUtilities().then((res) => {
        setUtilities(res);
      });

      DataService.fetchContinents().then((res => {
        setContinents(res);
      }));

      DataService.fetchCompany(companyWallet).then((res => {
        setCompany(res);        
        DesignerService.fetchDesigners(res.id).then((res => {
          setDesigners(res);
        }));
      }));

      // fetchPriceRate();
      // const timer = setTimeout(() => {
      //     fetchPriceRate();
      // }, 60000);

      // return () => clearTimeout(timer);
    }
    // eslint-disable-next-line
  }, [signedUser.id]);

  // const fetchPriceRate = () => {
  //   DataService.fetchExchangeData().then((res => {
  //     setPriceRate(res);
  //   }));
  // }

  useEffect(() => {
    if(category) {
      let isNecessaryExist = false;
      for (let i=0; i<variables.length; i++) {
        if(parseInt(variables[i].necessary) === category) {
          isNecessaryExist = true;
        }
      }

      if(isNecessaryExist) {
        setFilteredVariables(variables.filter((item) => parseInt(item.necessary) === category));
      }
      else {
        setFilteredVariables(variables.filter((item) => item.necessary === null));
      }
      setUpdate(!isUpdate);
    }
  }, [category]);

  useEffect(() => {
    dispatch(actions.fetchAssetDetail(nftId));
    // eslint-disable-next-line
  }, [dispatch, nftId]);

  useEffect(() => {
    if(nft?.id && nft?.id === parseInt(nftId)) {
      const initialVariable = [{
        value: "0",
        variable: 'Level'
      }];
      const updatedVariableJson = updateLevelValue(initialVariable.concat(nft?.variable 
        ? nft?.variable.filter((item) => item.variable !== 'Level') 
        : []));
      setVariableJson(updatedVariableJson);
    
      if (nft?.variable){
        const sortedVariable = nft?.variable.filter((item) => item.variable !== 'Level').sort(function(a, b) {
          return (a.id !== undefined ? a.id : 0) - (b.id !== undefined ? b.id : 0);
        });
        const updatedVariableJson = updateLevelValue(initialVariable.concat(sortedVariable));
        setVariableJson(updatedVariableJson);
      }
    }
  }, [nft?.variable])

  useEffect(() => {
    if(nft?.id && nft?.id === parseInt(nftId)) {
      if(nft?.tag) {
        setTagIds(JSON.parse(nft?.tag));
      }

      if(nft?.utility) {
        setUtilityIds(JSON.parse(nft?.utility));
      }
    }
  }, [nft?.tag, nft?.utility])

  useEffect(() => {
    if(nft && designers.length > 0) {
      setDescription(nft.description);
      setPrice(Web3.utils.fromWei(nft.price, 'gwei') * 1000);
      setTitle(nft.title);
      setCategory(nft?.categoryId);
      setIsForBid(nft?.isForBid);
      setIsListed(nft?.listedAsset);
      const currentDesigner = designers.find(element => element.id === nft?.designerId);
      setDesigner(currentDesigner?.name.replace(' ', ''));
      setNumberOfItem(nft?.numberOfItems);
      setLimitDate(nft?.limitDate);
      setVideoUrl(nft?.video);
      setCategory(nft?.categoryId);
      generateTreeSelectionFromCategory(nft?.Category, []);
      setMediaId(nft?.media);
      setVideoId(nft?.mediaFile);
      let images = [];
      for(let i=0; i<nft?.previewImages?.length; i++) {
        images.push({
          name: nft?.previewImages[i],
          exists: true
        });
      }
      setPreviewImages(images);
    }
    // eslint-disable-next-line
  }, [nft, designers]);

  useEffect(() => {
    if(mediaId) {
      const timer = setTimeout(() => {
        VideoService.getMediaList(mediaId).then(res => {

          if(res.data?.data?.encoded_medias?.length > 1) {
            let fileName = res.data?.data?.encoded_medias[1].id;
            let mimeType = res.data?.data?.encoded_medias[1].mimetype;
            fileName += '.' + mimeType.split(/\//)[1];
            setVideoId(fileName);
          } else {
            let fileName = res.data?.data?.encoded_medias[0].id;
            let mimeType = res.data?.data?.encoded_medias[0].mimetype;
            fileName += '.' + mimeType.split(/\//)[1];
            setVideoId(fileName);
          }
          setActive(false);
        }).catch((error) => {
          setActive(false);
          let msg = (typeof error === 'object') ? error.message : error;
          Notification(msg, false);
        })
      }, 15000); // Delay in milliseconds (e.g., 3000 ms = 3 seconds)
      return () => clearTimeout(timer);
    }
  }, [mediaId])


  const updateLevelValue = (data) => {
    const updatedVariableJson = data;
    let sumOfNumberValue = 0;
    let countOfNumberValue = 0;
    for(let i=1; i<updatedVariableJson.length; i++) {
      if(updatedVariableJson[i].format === 'STAR') {
        sumOfNumberValue += parseInt(updatedVariableJson[i].value / 10);
        countOfNumberValue++;
      }
    }

    updatedVariableJson[0].value = countOfNumberValue === 0 ? 0 : sumOfNumberValue;

    return updatedVariableJson;
  }

  const addVariable = (value) => {
    const updatedVariableJson = updateLevelValue([...variableJson, value]);
    setVariableJson(updatedVariableJson);
  }

  const addTag = (value) => {
    dispatch(setActions.setLoading(true));
    TagService.createTag({name: value}).then((res) => {
      setTags(res);
      setNewTag('');
      dispatch(setActions.setLoading(false));
      Notification('Your Tag has been properly added to the Tag list.', true);
    }).catch(error => {
      dispatch(setActions.setLoading(false));
      let msg = (typeof error === 'object') ? error.message : error;
      Notification(msg, false);
    });
  }

  const addUtility = (value) => {
    dispatch(setActions.setLoading(true));
    UtilityService.createUtility({name: value}).then((res) => {
      setUtilities(res);
      setNewUtility('');
      dispatch(setActions.setLoading(false));
      Notification('Your Utility has been properly added to the Utility list.', true);
    }).catch(error => {
      dispatch(setActions.setLoading(false));
      let msg = (typeof error === 'object') ? error.message : error;
      Notification(msg, false);
    });
  }

  const removeVariable = (id) => {
    const updatedVariableJson = updateLevelValue(variableJson.filter((item) => item.id !== id));
    setVariableJson(updatedVariableJson);
  }

  const updateVariable = (id, value) => {
    const updatedVariableJson = updateLevelValue(variableJson.map(obj => {
      if (obj.id === id) {
        return {...obj, "value": value};
      }

      return obj;
    }))
    setVariableJson(updatedVariableJson);
  }

  const generateTreeSelectionFromCategory = (category, selectedTreeArray) => {
    let result = [];
    if(!category) {
      setSelectedTree(result);
      return;
    }
    for (let i=0; i<selectedTreeArray.length; i++) {
      result.push({level: selectedTreeArray[i]['level'] + 1, id: selectedTreeArray[i]['id']});
    }
    result.push({level: 0, id: category.id})
    if(category.parent !== 0) {
      generateTreeSelectionFromCategory(categories.find(({ id }) => id === category.parent), result);
    }
    else {
      setUpdate(!isUpdate);
      setSelectedTree(result);
    }
  }

  const generateTreeData = (data) => {
    const result = data.reduce(function (r, a) {
      r[a.parent] = r[a.parent] || [];
      r[a.parent].push(a);
      return r;
    }, Object.create(null));

    formatData(data, result, true).then((formatedData) => {
      setTreeData(formatedData);
    });
  }

  const formatData = async (data, obj, isInit) => {
    let result = [];
    for(let i=0; i<data.length; i++) {
      if(!isInit || (isInit && data[i]['parent'] === 0)) {
        if(obj[data[i]['id']]) {
          result.push({
            id: data[i]['id'],
            name: data[i]['name'],
            parent: data[i]['parent'],
            children: await formatData(obj[data[i]['id']], obj, false)
          });
        }
        else {
          result.push({
            id: data[i]['id'],
            name: data[i]['name'],
            parent: data[i]['parent'],
            children: []
          })
        }
      }
    }

    return result;
  }

  const checkCategorySelected = (Categoryid) => {
    if(selectedTree.find(({ id }) => id === Categoryid))
      return true;
    return false;
  }

  const renderTree = (nodes, level, available) => (
    <TreeItem key={nodes.id} nodeId={`${nodes.id}`} label={
        <div>
          <input type="radio" name={'name' + nodes['parent']} value={nodes['id'] || null} id={nodes['name'] + nodes['id']} onChange={(e) => changeTreeSelection(e, level, nodes.id) } disabled={!available} checked={!available ? false : (checkCategorySelected(nodes.id) ? true : null)}/>
          <label htmlFor={nodes['name'] + nodes['id']}>&nbsp;{nodes.name}</label>
        </div>
      }
      onClick={
        () => handleTreeItemClick(nodes.name)
      }
      >
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node, level + 1, checkChildrenAvailable(level, nodes['id'])))
        : null}
    </TreeItem>
  );

  const addNewSubCategory = async () => {
    setShowAddNewModal(false);
    dispatch(setActions.setLoading(true));
    const data = {
      'name': newSubCategory,
      'parent': selectedNode.length === 0 ? 0 : selectedNode,
    }
    try {      
      const result = await CategoryService.createCategory(data);
      setNewSubCategory('');
      let temp = categories;
      temp.push(result);
      setCategories(temp);
      generateTreeData(temp);
      dispatch(setActions.setLoading(false));

      Notification('Your Sub-Category has been properly added to the Category list.', true);
    }
    catch(error) {
      dispatch(setActions.setLoading(false));
      let msg = (typeof error === 'object') ? error.message : error;
      Notification(msg, false);
    }
  }

  const changeTreeSelection = (e, level, id) => {
    if(e.target.checked) {
      let temp = selectedTree.filter((item) => item.level < level);
      temp.push({level: level, id: id});
      setCategory(id);
      setUpdate(!isUpdate);
      setSelectedTree(temp);
    }
  }

  const handleTreeItemClick = (name) => {
      setSelectedNodeName(name);
  }

  const checkChildrenAvailable = (level, id) => {
    for(let i=0; i<selectedTree.length; i++) {
      if(selectedTree[i].level === level && selectedTree[i].id === id) {
        return true;
      }
    }
    return false;
  }

  const handleSaveItem = async () => {
    const amount = Web3.utils.toWei(typeof(price) === 'number' ? price.toString() : price, 'gwei') / 1000;
    let cid;

    if(numberOfItem < nft.minted && numberOfItem > 0) {
      setNumberOfItemError(true);
    }
    else {
      setActive(true);

      if (previewImg) {
        // update cid when changing preview
        const res = await AssetService.updatePreview(previewFormData);
        if (res.status) {
          cid = res?.cid;
        } else {
          cid = nft?.cid;
        }
      } else {
        cid = nft?.cid;
      }

      const formData = new FormData();
      formData.append("directory", nft?.directory);
      formData.append("removedItems", previewRemovedFiles.length);
      for(let i=0; i<previewRemovedFiles.length; i++) {
        formData.append(`remove_${i}`, previewRemovedFiles[i].name);
      }
      for(let i=0; i<previewFiles.length; i++) {
        formData.append(`preview_${i}`, previewFiles[i], previewFiles[i].name);
      }

      await UploadService.updatePreviewImage(formData);
      
      var selectedDesigner = designers.find((item) => item.name === designer);

      let designerId;
      if(selectedDesigner) {
        designerId = selectedDesigner.id;
      } else if (designer) {
        const data = {
          'name': designer,
          'companyId': company.id,
        }
        const newDesigner = await DesignerService.createDesigner(data);
        designerId = newDesigner.id;
        let tempDesigners = designers;
        tempDesigners.push(newDesigner);
        setDesigners(tempDesigners)
      }

      let previewImagesJSON = [];

      for(let i=0; i<previewImages.length; i++) {
        if(previewImages[i].exists) previewImagesJSON.push(previewImages[i].name);
      }
      for(let i=0; i<previewFiles.length; i++) {
        previewImagesJSON.push(previewFiles[i].name);
      }
    
      const metadata = {
        'title': title,
        'description': description,
        'price': amount,
        'isForBid': isForBid,
        'categoryId': category,
        'listedAsset': isListed,
        'numberOfItems': numberOfItem,
        'limitDate': limitDate,
        'designerId': designerId,
        'cid': cid,
        'variable': variableJson,
        'tag': JSON.stringify(tagIds),
        'utility': JSON.stringify(utilityIds),
        'media': mediaId,
        'mediaFile': videoId,
        'video': videoUrl,
        'previewImages': previewImagesJSON
      };
      try {
        await AssetService.updateAsset(nft?.id, metadata);

        Notification(`Your changes have been applied.`, true);

        setActive(false);

        setTimeout(function() { //Start the timer
          history.push({pathname: EPATHS.MY_COLLECTIONS})
          // eslint-disable-next-line
        }.bind(this), 5000)
      }
      catch(error) {
        Notification(`${error}`, false);

        setActive(false);
      }
    }
  }

  const handleAsset = async(event) => {
    let file = event.target.files[0];

    if(!file) {
      return;
    }

    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setPreviewImg(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }

    const formData = new FormData();
    formData.append("asset", file, file.name);
    formData.append("assetId", nft?.id);
    formData.append("directory", nft?.directory);

    setPreviewFormData(formData);
  };

  const onFileChangeCapture = (e) => {
    setActive(true);
    var formData = new FormData();
    formData.append('file', e.target.files[0]);
    formData.append('folder', process.env.REACT_APP_MEDIA_FOLDER);
    VideoService.uploadVideo(formData).then(res => {
      setMediaId(res.data.data.id);
    }).catch((error) => {
      setActive(false);
      let msg = (typeof error === 'object') ? error.message : error;
      Notification(msg, false);
    })
  };

  return (
    <div>
      <GlobalStyles />
      {/* <StyledHeader theme={theme} /> */}
      <Header className='HOME' />
      <LoadingOverlay
        active={isActive}
        spinner
        text="please wait...">
      <ReactNotifications />
      <section className='container'>
        <div className='row mt-md-5 pt-md-4'>
          <div className='col-md-6 text-center'>
            <div style={{border: '1px solid #DDD', borderRadius: 15}}>
              { nft && nft.gltf && nft?.id === parseInt(nftId) &&
                  <Nft3dCard src={`${api.server3dnTech}/${nft.directory}/${nft.gltf}`} directory={`${api.server3dnTech}/${nft.directory}/`} height={450} border={true} ratio={0.7} />
              }
              { nft && !nft.gltf && <img src={api.ipfsURL + nft.image} className="img-fluid img-rounded mb-sm-30" alt=""/>}
              {nft && nft.gltf && <p style={{fontWeight: 700}}>To view this 3D Assets, click on the image and move your mouse. You can also mouse scroll to zoom-in and zoom-out in the preview</p>}
              {nft && nft.gltf && <p style={{fontSize: 14}}>Disclaimer: The 3D Asset rendering is subject to differences with the real version available inside the Metaverse. Please check preview Video to have an exact render of the asset.</p>}
            </div>
            <div>
              
              <h5>{t("PreviewItem")}</h5>
              <div className="nft__item m-0">
                  {nft && <img src={previewImg ? previewImg : api.server3dnTech + '/' + nft.directory + '/' + nft.directory + '.png?' + (new Date())} style={{maxWidth: 300}} alt=''/>}
              </div>

              <div className="spacer-10"></div>

              <h5>{t("UploadFile")}</h5>
              <div className="d-create-file" style={{paddingTop: 10, paddingBottom: 10}}>
                  <p id="file_name">PNG, JPG file.</p>
                  {/* {this.state.files.map(x => 
                  <p key="{index}">PNG, JPG, GIF, WEBP or MP4. Max 200mb.{x.name}</p>
                  )} */}
                  <div className='browse'>
                    <label htmlFor="get_file" style={{ background: "#f8f9fa", padding:"5px 10px", borderRadius: 5, border: '1px solid grey' }}>
                      Browse
                    </label>
                    <input ref={inputRef} type="file" id="get_file" className="btn-main" onChange={(event) => {handleAsset(event)}} style={{visibility:"hidden", display: 'none'}} accept="image/png, image/jpeg"/>
                    {/* <input id='upload_file' type="file" multiple onChange={this.onChange} /> */}
                  </div>                        
              </div>

              <h5 className='mt-5'>Preview Images</h5>
              <ImageUpload selectedImages={previewImages} setSelectedImages={setPreviewImages} isEnabled previewFiles={previewFiles} setPreviewFiles={setPreviewFiles} directory={nft?.directory} setPreviewRemovedFiles={setPreviewRemovedFiles} />
              {previewImg && <span onClick={() => {setPreviewImg(null); inputRef.current.value = null;}} className="btn-main lead me-2" style={{marginTop: 10}}>Revert</span>}
            </div>
          </div>
          <div className='col-md-6'>
            <div className='item_info'>
              {nft && nft.item_type === 'on_auction' && (
                <>
                  {t("AuctionEnd")}
                  <div className='de_countdown'>
                    <Clock deadline={nft.deadline} />
                  </div>
                </>
              )}
              <h5>{t("Title")}</h5>
              <input
                type="text"
                name="item_title"
                id="item_title"
                className="form-control"
                placeholder={`${t("EG")}" "${t("CryptoFunk")}`}
                onChange={(event) => {
                  setTitle(event.target.value);
                  debounceCheckTitleValidation(event.target.value);
                }}
                value={title}/>
              {errorMessage && <p className='error-input'>{errorMessage}</p>}

              <div className="spacer-10"></div>

              <h5>{t("Not Activated / Activated")}</h5>
              <GreenSwitch checked={isListed} onChange={(event) =>{ setIsListed(event.target.checked);} }/>

              <div className="spacer-10"></div>

              <h5>{t("Type of Sell")}</h5>
              <select name="item_title" id="item_title" className="form-control" placeholder={``} onChange={(event) => setIsForBid(event.target.value)} value={isForBid}>
                <option value={false}>Fixed Price</option>
                {/* <option value={true}>Open for bids</option> */}
              </select>

              <div className="spacer-10"></div>

              <h5>{t("Category")}</h5>
              {treeData.map((tree, i) => (
                <TreeView
                  key={i}
                  multiSelect={false}
                  aria-label="rich object"
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}
                  sx={{ height: 110, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                  selected={selectedNode}
                  onNodeSelect={(event, nodeIds) => setSelectedNode(nodeIds)}
                >
                  {renderTree(tree, 0, true)}
                </TreeView>
              ))}
              
              <input type="text" name="item_price_usdt" className="form-control newSubCategory" placeholder={selectedNodeName ? t("Input new sub-category") : t("Input new category")} value={newSubCategory} onChange={(event) => setNewSubCategory(event.target.value)}/>
              <button className='btn-main lead mb-5 mr15' onClick={(e) => {e.preventDefault(); setShowAddNewModal(true)}} disabled={!newSubCategory}>Add</button>

              <div className="spacer-10"></div>

              <h5>{t("Variables")}</h5>
              {
                variableJson.map((item) => (
                  <VariableItem key={item['id']} item={item} isCompleted={true} removeVariable={removeVariable} continents={continents} updateVariable={updateVariable} disabled />
                ))
              }

              <VariableItem item={currentVariableItem} isCompleted={false} setCurrentVariableItem={setCurrentVariableItem} addVariable={addVariable} variableList={filteredVariables.filter((item) => {
                const idArray = variableJson.map((el) => {return el.id;});
                return !idArray.includes(item['id']);
              })} continents={continents} />

              <div className="spacer-10"></div>

              <h5>{t("Tags")}</h5>

              <TagsDropDown tags={tags} newTag={newTag} setNewTag={setNewTag} addTag={addTag} tagIds={tagIds} setTagIds={setTagIds}/>

              <div className="spacer-10"></div>

              <h5>{t("Utilities")}</h5>

              <UtilitiesDropDown utilities={utilities} newUtility={newUtility} setNewUtility={setNewUtility} addUtility={addUtility} utilityIds={utilityIds} setUtilityIds={setUtilityIds} />

              <div className="spacer-10"></div>

              <h5>{t("Description")}</h5>
              <textarea data-autoresize name="item_desc" id="item_desc" className="form-control" placeholder={`${t("EG")}" "${t("VeryLimitedItem")}`} onChange={(event) => setDescription(event.target.value)} value={description}></textarea>

              <div className="spacer-10"></div>

              <h5>{t("Youtube Video URL")}</h5>
              <input type="url" name="item_video_url" id="item_video_url" className="form-control" placeholder={t("Youtube Video URL")} onChange={(event) => {setVideoUrl(event.target.value)}} value={videoUrl}/>

              <div className="spacer-10"></div>

              <h5>{t("Upload Video")}</h5>
              <input type="file" onChange={onFileChangeCapture} style={{visibility:"hidden"}} ref={inputVideoRef} />
              <button className='btn-main lead mb-5 mr15' onClick={(e) => {
                e.preventDefault();
                inputVideoRef.current.click();
              }}>Upload</button>

              {videoId && mediaId && <iframe frameborder="0" width="500" height="350"  src={`https://play.vod2.infomaniak.com/single/${mediaId}/${process.env.REACT_APP_MEDIA_PLAYER}/${videoId}`} allowfullscreen></iframe>}

              <div className="spacer-10"></div>

              <h5>{t("Price in USDC")}</h5>
              <input type="number" step="0.01" name="item_price" id="item_price" className="form-control" placeholder={t("PriceETH")} onChange={(event) => setPrice(event.target.value)} value={price}/>

              <div className="spacer-10"></div>

              {/* <h5>{t("Price in USDT")}</h5>
              <input type="number" step="0.01" disabled className="form-control" placeholder={t("PriceETH")} value={price && priceRate ? (price * priceRate).toFixed(2) : ''} onChange={(event) => {}} />

              <div className='spacer-40'></div> */}

              <h5>{t("Number of Item to sell")}</h5>
              
              <input type="number" step="1" name="number_of_item" id="number_of_item" className="form-control" placeholder={t("Number of Item to sell")} min={signedUser.roleId === 1 ? '0' : '1'} onChange={(event) => setNumberOfItem(event.target.value)} value={numberOfItem} style={{marginBottom: 0}}/>
              {numberOfItem !== 0 && <span>{`${nft?.minted} Items Minted being (${numberOfItem - nft?.minted}  /${numberOfItem})`}</span>}

              {numberOfItem == 0 && <><div className="spacer-10"></div>

              <h5>{t("Limit Date")}</h5>
              <input type="date" name="limit_date" id="limit_date" className="form-control" placeholder={t("Limit Date")} onChange={(event) => setLimitDate(event.target.value)} value={limitDate}/></>}

              <div className="spacer-10"></div>

              <h5>{t("Designers")}</h5>
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                value={designer}
                getOptionLabel={(option)=>{
                  // Value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return option;
                  }
                  if (!option) {
                    return '';
                  }
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  return option.name;
                }}
                options={designers.map((option) => option?.name)}
                onInputChange={(event, newInputValue) => {
                  setDesigner(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} margin="normal" variant="outlined" className="form-control" value={designer} />
                )}
              />
              
              <div className='spacer-40'></div>

              <button onClick={(e) => {e.preventDefault(); handleSaveItem();}} className="btn-main lead me-2" disabled={errorMessage}>Save</button>
            </div>
          </div>
        </div>
        <Dialog
          open={numberOfItemError}
          onClose={() => setNumberOfItemError(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className='alert-modal'
        >
          <div className={classes.root}>
            <div className={classes.topBlock}>
                <HighlightOffIcon className={classes.icon} />
            </div>  
            <div className={classes.bottomBlock}>
                <Typography className={classes.supTitle}>
                  Number of Item to sell should be greater than number of minted items.
                </Typography>
                <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    startIcon={<CloseIcon />}
                    onClick={() => setNumberOfItemError(false)}
                >
                    {t("Close")}
                </Button>
            </div>
          </div>
        </Dialog>
        <Dialog
          open={showAddNewModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className='alert-modal'
        >
          <div className={classes.rootModal}>
            <div className={classes.topBlock}>
                <ErrorOutlineIcon className={classes.icon} />
            </div>  
            <div className={classes.bottomBlock}>
                <Typography className={classes.title}>
                    {t("Attention")}
                </Typography>
                <Typography className={classes.supTitle}>         
                  {selectedNodeName ? `Are you sure you want to add ${newSubCategory} to ${selectedNodeName} Category?` : `Are you sure you want to add ${newSubCategory} as a main Category?`}
                </Typography>
                <div className={classes.buttonBlock}>
                  <button
                      className="btn-main-cancel"
                      onClick={()=>setShowAddNewModal(false)}
                  >
                  {t("Cancel")} 
                  </button>
                  <button
                      className="btn-main"
                      onClick={() => addNewSubCategory()}
                  >
                      {t("OK")}
                  </button>
                </div>
            </div>
          </div>
        </Dialog>
      </section>
      </LoadingOverlay>
      <Footer />
    </div>
  );
};

export default memo(EditItem);
