import { axiosWithoutEncryption } from '../utils/functions/apiCallWithToken';

const server3dntechURL = process.env.REACT_APP_3DNTECH_SERVER;


export const uploadAsset = async (data) => {
    const result = await axiosWithoutEncryption(`${server3dntechURL}/asset/upload/`, 'post', data);
    return result;
}

export const uploadConfirm = async (data) => {
    const result = await axiosWithoutEncryption(`${server3dntechURL}/asset/confirm/`, 'post', data);
    return result;
}

export const updatePreview = async (data) => {
    const result = await axiosWithoutEncryption(`${server3dntechURL}/asset/preview/`, 'post', data);
    return result;
}

export const updatePreviewImage = async (data) => {
    const result = await axiosWithoutEncryption(`${server3dntechURL}/asset/update/`, 'post', data);
    return result;
}
