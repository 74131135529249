import React, { useEffect, useState } from 'react';
import { StyledHeader } from '../MarkePlace/Styles';
import Header from '../MarkePlace/menu/header';
import mapImg from '../../assets/images/map.png';
import GridOverlay from './GridOverlay';
import { MAP_WIDTH, MAP_HEIGHT, ZOOM_RATE } from './Const';
import Legend from './Legend';
import ZoomModule from './ZoomModule';
import ParcelDetails from './ParcelDetails';
import { debounce } from 'lodash';
import LoadingOverlay from 'react-loading-overlay-ts';
import 'react-notifications-component/dist/theme.css';
import { ReactNotifications } from 'react-notifications-component';
import ToggleIcon from './ToggleIcon';

const theme = 'HOME'; //LIGHT, GREY, RETRO

function TileMap() {
  const [zoomLevel, setZoomLevel] = useState(4); // Zoomed level 8 - Max, equals tile size
  const [coordX, setCoordX] = useState((window.innerWidth - MAP_WIDTH * Math.pow(ZOOM_RATE, zoomLevel)) / 2); // Current Map's Center X
  const [coordY, setCoordY] = useState((window.innerHeight - MAP_HEIGHT * Math.pow(ZOOM_RATE, zoomLevel)) / 2 + 42); // Current Map's Center Y
  const [isMoving, setIsMoving] = useState(false);
  const [dragStartX, setDragStartX] = useState(0);
  const [dragStartY, setDragStartY] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const [activeParcel, setActiveParcel] = useState();
  const [isActive, setActive] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('Please wait...');
  const [isUpdated, setUpdated] = useState(false);
  const [isUpdatingDetails, setIsUpdatingDetails] = useState(false);
  const [showLegend, setShowLegend] = useState(true);

  const setFinishDragging = () => {
    setIsDragging(false);
  }

  const setFinishScrolling = () => {
    setIsScrolling(false);
  }
  const [debounceSetFinishDragging] = useState(() => debounce(setFinishDragging, 200));
  const [debounceSetFinishScrolling] = useState(() => debounce(setFinishScrolling, 200));

  // Math.pow(ZOOM_RATE, zoomLevel) equals current tile component's width & height value.
  useEffect(() => {
    if (!isMoving) return;

    const handleMouseMove = (event) => {
      if(isMoving === true) {
        if(coordX + (event.clientX - dragStartX) <= 100 && coordX + (event.clientX - dragStartX) >= window.innerWidth - MAP_WIDTH * Math.pow(ZOOM_RATE, zoomLevel) - 100) setCoordX(coordX + (event.clientX - dragStartX));
        if(coordY + (event.clientY - dragStartY) <= 150 && coordY + (event.clientY - dragStartY) >= window.innerHeight - MAP_HEIGHT * Math.pow(ZOOM_RATE, zoomLevel) - 100) setCoordY(coordY + (event.clientY - dragStartY));
        if(Math.abs(event.clientX - dragStartX) > 20 || Math.abs(event.clientY - dragStartY > 20)) setIsDragging(true);
      }
    }

    function handleMouseUp(e) {
      e.stopPropagation();
      e.preventDefault();
      setIsDragging(false);
      setIsMoving(false);
    }

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
    // eslint-disable-next-line
  }, [isMoving]);

  const handleMouseDown = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setIsMoving(true);
    setDragStartX(event.clientX);
    setDragStartY(event.clientY);
  }

  const handleWheel = (event) => {
    setIsScrolling(true);
    const imgInnerX = event.clientX - coordX;
    const imgInnerY = event.clientY - coordY;

    if(coordX < event.clientX && coordY < event.clientY && coordX + MAP_WIDTH * Math.pow(ZOOM_RATE, zoomLevel) > event.clientX && coordY + MAP_HEIGHT * Math.pow(ZOOM_RATE, zoomLevel) > event.clientY) {
      if(event.nativeEvent.wheelDelta > 0) {
        if(zoomLevel < 7) {
          setZoomLevel(zoomLevel + 1);
          setCoordX(coordX - (imgInnerX * (ZOOM_RATE - 1)));
          setCoordY(coordY - (imgInnerY * (ZOOM_RATE - 1)));
        }
      }
      else {
        if(zoomLevel > 0) {
          setZoomLevel(zoomLevel - 1);
          setCoordX(zoomLevel === 1 ? (window.innerWidth - MAP_WIDTH * Math.pow(ZOOM_RATE, (zoomLevel - 1))) / 2 : coordX + (imgInnerX * (1 - 1/ZOOM_RATE))); //Last zoom level's coord x value center
          setCoordY(zoomLevel === 1 ? (window.innerHeight - MAP_HEIGHT * Math.pow(ZOOM_RATE, (zoomLevel - 1))) / 2 + 42 : coordY + (imgInnerY * (1 - 1/ZOOM_RATE)));  //Last zoom level's cood y value center
        }
      }
      debounceSetFinishScrolling();
    }
  }
  
  return (
    <div>
      <LoadingOverlay
        active={isActive}
        spinner
        text={loadingMessage}>
      <StyledHeader theme={theme} />
      <Header className="HOME"/>
      <ReactNotifications />
      <div
        style={{position: 'relative', paddingTop: 85, height: '100vh', overflow: 'hidden'}}
        onWheel={(e) => handleWheel(e)}
        onMouseDown={(e) => handleMouseDown(e)}
      >
        {zoomLevel > 3 && !isDragging && !isScrolling && <GridOverlay
          zoomLevel={zoomLevel}
          offsetX={coordX % Math.pow(ZOOM_RATE, zoomLevel)}
          offsetY={coordY % Math.pow(ZOOM_RATE, zoomLevel)}
          startXPos={Math.ceil(coordX / Math.pow(ZOOM_RATE, zoomLevel)) + 1}
          startYPos={Math.floor(coordY / Math.pow(ZOOM_RATE, zoomLevel)) + 1}
          width={Math.ceil(window.innerWidth / Math.pow(ZOOM_RATE, zoomLevel)) + 1}
          height={Math.ceil(window.innerHeight / Math.pow(ZOOM_RATE, zoomLevel)) + 1}
          activeParcel={activeParcel}
          setActiveParcel={setActiveParcel}
          isUpdated={isUpdated}
          isUpdatingDetails={isUpdatingDetails}
          setIsUpdatingDetails={setIsUpdatingDetails}
        />}
        <img 
          src={mapImg}
          alt=""
          style={{ width: Math.pow(ZOOM_RATE, zoomLevel) * MAP_WIDTH, height: Math.pow(ZOOM_RATE, zoomLevel) * MAP_HEIGHT, position: 'absolute', top: coordY, left: coordX }}
          onWheel={(e) => handleWheel(e)}
          onMouseDown={(e) => handleMouseDown(e)}
        />
      </div>
      <ToggleIcon showLegend={showLegend} setShowLegend={setShowLegend} zoomLevel={zoomLevel} />
      {showLegend && <>
        <Legend zoomLevel={zoomLevel} />
        <ZoomModule 
          coordX={coordX}
          coordY={coordY}
          mapWidth={Math.pow(ZOOM_RATE, zoomLevel) * MAP_WIDTH}
          setZoomLevel={setZoomLevel}
          zoomLevel={zoomLevel}
          setCoordX={setCoordX}
          setCoordY={setCoordY}
        />
      </>}
      {activeParcel && <ParcelDetails
        activeParcel={activeParcel}
        setActiveParcel={setActiveParcel}
        setActive={setActive}
        setLoadingMessage={setLoadingMessage}
        isUpdated={isUpdated}
        setUpdated={setUpdated}
      />}
      </LoadingOverlay>
    </div>
  );
}

export default TileMap;
