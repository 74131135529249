import React, { useEffect, useState } from 'react';
import Web3 from "web3";
import { Typography,Dialog } from '@material-ui/core';
import { TextField, IconButton } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import api from '../../core/api';
import LandPlaceholder from '../../assets/images/land-placeholder.webp'
import { useSelector, useDispatch } from 'react-redux';
import * as selectors from '../../store/selectors';
import * as actions from '../../store/actions/thunks';
import * as setActions from '../../store/actions';
import MarketplaceAbi from "../../artifacts/contracts/Marketplace.sol/Marketplace.json";
import NFTAbi from "../../artifacts/contracts/Collectible.sol/Collectible.json";
import SNAPSHOTAbi from "../../artifacts/contracts/MarketplaceSnapshot.sol/MarketplaceSnapshot.json";
import erc20Abi from "../../artifacts/contracts/IERC20.sol/IERC20.json";
import useStyles from '../../styles/pages/Explore/AssetDetails';
import { Store } from 'react-notifications-component';
import { useTranslation } from 'react-i18next';
import { login } from '../../utils/functions/authenticate';
import { useHistory } from 'react-router';
import { EROLES } from "../../utils/constants/roles.enum";
import enableWeb3 from "../../utils/functions/enableWeb3";
import { debounce } from 'lodash';

import * as TileService from '../../services/TileService';
import * as CouponService from "../../services/CouponService";
import * as AuthService from "../../services/AuthService";
import { uploadAsset } from '../../services/AssetService';
import Allowance from "../../utils/functions/allowance";
import Notification from "../../utils/functions/notification";
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { BigNumber } from '@ethersproject/bignumber';
import { LAND_PACK, MAP_INFRA_COLOR, MAP_LAND_LABEL, TIER1, TIER2, TIER3, TIER4, TIER5 } from './Const';
import { useWriteContract, useTransactionConfirmations } from 'wagmi';
import { useAppKit } from '@reown/appkit/react';

function ParcelDetails({activeParcel, setActiveParcel, setActive, setLoadingMessage, isUpdated, setUpdated}) {
	const signedUser = useSelector(selectors.user);
  const [marketplaceContract, setMarketplaceContract] = useState();
  const [nftContract, setNftContract] = useState();
  const [eivContract, setEivContract] = useState();
  const [snapshotContract, setSnapshotContract] = useState();

  const [openCheckout, setOpenCheckout] = useState(false);
  const [openCheckoutbid, setOpenCheckoutbid] = useState(false);
  const [openWithdrawBid, setOpenWithdrawBid] = useState(false);
  const [openAddListing, setOpenAddListing] = useState(false);

  const [isBidAvailable, setBidAvailable] = useState(true);
  // eslint-disable-next-line
  const [showTransferNft, setShowTransferNft] = useState(false);

  const [isPriceError, setPriceError] = useState(false);
  const [price, setPrice] = useState("");
  const [bidAmount, setBidAmount] = useState();
  const [sellPrice, setSellPrice] = useState("");
  const [isForBid, setIsForBid] = useState(false);
  // eslint-disable-next-line
  const [isListed, setIsListed] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [vaultAmount, setVaultAmount] = useState(0);
  const [vaultPercent, setVaultPercent] = useState(0);
  const [isVaultChecked, setVaultChecked] = useState(false);
  const [isVault, setIsVault] = useState();
  const [exchangeRate, setExchangeRate] = useState(false);
  const [exchangeRateScale, setExchangeRateScale] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [isCouponError, setCouponError] = useState(false);
  const [isCouponSet, setCouponSet] = useState(false);
  const [isCouponOverdue, setCouponOverdue] = useState(false);
  const [isFetchingCoupon, setFetchingCoupon] = useState(false);
  const [couponPrice, setCouponPrice] = useState("");
  const [royalty, setRoyalty] = useState(0);
  const [txProcessing, setTxProcessing] = useState(false);
  const [isSendClicked, setSendClicked] = useState(false);
  const [transferAddress, setTransferAddress] = useState(0);
  const [transferAddressList, setTransferAddressList] = useState([]);
  const [searchAddress, setSearchAddress] = useState();
  const { open } = useAppKit();
  const history = useHistory();

  const marketplaceAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
  const snapshotAddress = process.env.REACT_APP_SNAPSHOT_CONTRACT;
  const nftAddress = process.env.REACT_APP_NFT_ADDRESS;
  const walletAddress = process.env.REACT_APP_COMPANY_WALLET_ADDRESS;
  const eivAddress = process.env.REACT_APP_EIV_ADDRESS
  const web3 = new Web3(new Web3.providers.HttpProvider(process.env.REACT_APP_AVALANCHE_URL));
  const [currentSM, setCurrentSM] = useState();
  const { writeContract, isSuccess, isError, data, error } = useWriteContract();
  const [hash, setHash] = useState();
  const { status } = useTransactionConfirmations({hash: hash});
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes=useStyles();

  const fetchAddress = (value) => {
    setTransferAddressList([]);
    if(value === '' || value === null) {
      setTransferAddress(0);
    }
    else {
      AuthService.fetchUsersByAddress({address: value}).then(res => {
        setTransferAddressList(res);
      });
    }
  }

  // eslint-disable-next-line
	useEffect(() => {
    // if(window.ethereum) {
      const marketplaceContract = new web3.eth.Contract(MarketplaceAbi.abi, marketplaceAddress);
      const nftContract = new web3.eth.Contract(NFTAbi.abi, nftAddress);
      const eivContract = new web3.eth.Contract(erc20Abi.abi, eivAddress);
      const snapshotContract = new web3.eth.Contract(SNAPSHOTAbi.abi, snapshotAddress);

      setMarketplaceContract(marketplaceContract);
      setNftContract(nftContract);
      setSnapshotContract(snapshotContract);
      setEivContract(eivContract);
    // }

    // eslint-disable-next-line
	}, []);
	
	useEffect(() => {
    if (signedUser.metamask && activeParcel && marketplaceContract) {
      // const account = signedUser.metamask;

      // if (activeParcel?.TileBids?.length > 0) {
      //   marketplaceContract.methods.getBid(activeParcel.tokenId, account).call().then((res) => setBidAmount(res));
      // }
      setCouponPrice(parseInt(activeParcel?.TileGroup ? activeParcel?.TileGroup?.price : activeParcel?.price));
      marketplaceContract.methods.EXCHANGE_RATE_SCALE().call().then(result => {
        setExchangeRateScale(parseFloat(result));
      });

      marketplaceContract.methods.exchangeRate().call().then(result => {
        setExchangeRate(parseFloat(result));
      });

      dispatch(actions.fetchCurrentAuthor(walletAddress));
    }
  }, [signedUser.id, activeParcel, marketplaceContract])
  
  useEffect(() => {
    if(activeParcel && parseFloat(price) <= parseFloat(Web3?.utils?.fromWei(activeParcel.price, 'ether'))) {
      setPriceError(true);
    } else {
      setPriceError(false);
    }
    // eslint-disable-next-line
  }, [price]);

  useEffect(() => {
    const tx = JSON.parse(activeParcel.TileGroup ? (activeParcel?.TileGroup?.txHash ? activeParcel?.TileGroup?.txHash : '{}') : (activeParcel.txHash ? activeParcel.txHash : '{}'));
    if(tx.hash) {
      web3.eth.getTransaction(tx.hash).then(res => {
        if(res.from.toLowerCase() === signedUser.metamask) {
          setTxProcessing(true);
        }
        else {
          setTxProcessing(true);
        }
      })
    }
  }, [activeParcel?.txHash, activeParcel?.TileGroup?.txHash, signedUser])

  useEffect(() => {
    if(openAddListing) {
      marketplaceContract.methods.getNFTcommission(activeParcel.TileGroup ? activeParcel?.TileGroup?.tokenId : activeParcel.tokenId).call().then(res => {
        const [_, commission, royaltyAmount, authorRoyaltyAmount] = Object.values(res).map(Number);
        setRoyalty(royaltyAmount / 10);
      })
    }
  }, [openAddListing])

  useEffect(() => {
    setCoupon('');
    setCouponPrice(parseFloat(activeParcel?.TileGroup ? activeParcel?.TileGroup?.price : activeParcel?.price));
    setCouponError(false);
  }, [openCheckout]);

  useEffect(() => {
    if (status === 'success') {
      if(currentSM === 'cancel') {
        TileService.updateTileTransaction({
          'txHash': JSON.stringify({hash: data, type: 2}), //Type 0 is Buy, 1 Listing, 2 Cancel listing
          'type': activeParcel.TileGroup ? 1 : 0, //Type 0 is Tile, Type 1 is Tile Group
          'id': activeParcel.TileGroup ? activeParcel?.TileGroup?.id : activeParcel.id
        }).then(async (res) => {
          const metadata = {
            'txHash': data
          };
  
          setActiveParcel(null);
          let updated = await TileService.fetchTile(activeParcel?.id);
          setActiveParcel(updated);
  
          if(activeParcel.TileGroup) {
            await TileService.cancelTileGroupListing(activeParcel?.TileGroup?.id, metadata);
          }
          else {
            await TileService.cancelTileListing(activeParcel?.id, metadata);
          }
  
          setActiveParcel(null);
          updated = await TileService.fetchTile(activeParcel?.id);
          setActiveParcel(updated);
          dispatch(actions.fetchNotifications());
          setActive(false);
          setIsListed(true);
  
          Notification('Cancelled to sell the Land.', true);
        }).catch(error => {
          let msg = (typeof error === 'object') ? error.message : error;
          setActive(false);
          Notification(msg, false);
        });
      }
      else if(currentSM === 'eiv-approve') {
        setCurrentSM('buy-item');
        writeContract({ 
          abi: MarketplaceAbi.abi,
          address: marketplaceAddress,
          functionName: 'buyItem',
          args: [
            activeParcel.TileGroup ? activeParcel?.TileGroup?.tokenId : activeParcel?.tokenId,
            true,
            isVault,
            coupon ? true : false,
            coupon
          ],
        });
      }
      else if(currentSM === 'buy-item') {
        TileService.updateTileTransaction({
          'txHash': JSON.stringify({hash: data, isVault: isVault, coupon: coupon, type: 0}), //Type 0 is Buy, 1 Listing, 2 Cancel listing
          'type': activeParcel.TileGroup ? 1 : 0, //Type 0 is Tile, Type 1 is Tile Group
          'id': activeParcel.TileGroup ? activeParcel?.TileGroup?.id : activeParcel.id
        }).then(async (res) => {
          setActiveParcel(null);
          TileService.fetchTile(activeParcel?.id).then(r => {
            setActiveParcel(r);
          });
          
          const metadata = {
            'txHash': data,
            'tokenId': activeParcel.TileGroup ? activeParcel?.TileGroup?.tokenId : activeParcel?.tokenId,
            'isVault': isVault,
            'coupon': coupon
          }
          if(activeParcel.TileGroup) {
            await TileService.buyTileGroup(activeParcel?.TileGroup?.id, metadata);
          }
          else {
            await TileService.buyTile(activeParcel.id, metadata);
          }
    
          dispatch(actions.fetchNotifications());
          setActiveParcel(null);
          data = await TileService.fetchTile(activeParcel?.id);
          setActiveParcel(data);
          setOpenCheckout(false);
          setActive(false);
          Notification(`You purchased the Land ${activeParcel?.Continent?.name}_${activeParcel?.posX}_${activeParcel?.posY}_${activeParcel?.posZ} #${activeParcel.itemId}.`, true);
        }).catch(error => {
          let msg = (typeof error === 'object') ? error.message : error;
          setActive(false);
          Notification(msg, false);
        });
      }
      else if (currentSM === 'approve') {
        const amount = Web3.utils.toWei(sellPrice, 'gwei') / 1000;
        setCurrentSM('list');
        writeContract({ 
          abi: MarketplaceAbi.abi,
          address: marketplaceAddress,
          functionName: 'list',
          args: [
            activeParcel.TileGroup ? activeParcel?.TileGroup?.tokenId : activeParcel?.tokenId,
            amount
          ],
        });
      }
      else if (currentSM === 'list') {
        const amount = Web3.utils.toWei(sellPrice, 'gwei') / 1000;
        TileService.updateTileTransaction({
          'txHash': JSON.stringify({hash: data, price: amount, type: 1}), //Type 0 is Buy, 1 Listing, 2 Cancel listing
          'type': activeParcel.TileGroup ? 1 : 0, //Type 0 is Tile, Type 1 is Tile Group
          'id': activeParcel.TileGroup ? activeParcel?.TileGroup?.id : activeParcel.id
        }).then(async (res) => {
          setActiveParcel(null);
          let updated = await TileService.fetchTile(activeParcel?.id);
          setActiveParcel(updated);
    
          const metadata = {
            'price': amount,
            'txHash': data
          };
    
          if(activeParcel.TileGroup) {
            await TileService.addTileGroupListing(activeParcel?.TileGroup?.id, metadata);
          }
          else {
            await TileService.addTileListing(activeParcel?.id, metadata);
          }
    
          setOpenAddListing(false);
          setActiveParcel(null);
          updated = await TileService.fetchTile(activeParcel?.id);
          setActiveParcel(updated);
          dispatch(actions.fetchNotifications());
          setActive(false);
          setIsListed(true);
        }).catch(error => {
          let msg = (typeof error === 'object') ? error.message : error;
          setActive(false);
          Notification(msg, false);
        });
      }
      else if (currentSM === 'mint-approve') {
        if(activeParcel.TileGroup) {
          TileService.mintTileGroup(activeParcel?.TileGroup?.id, {isVault: isVault, coupon: coupon}).then(async (res) => {
            setActiveParcel(null);
            const data = await TileService.fetchTile(activeParcel?.id);
            setActiveParcel(data);
            dispatch(actions.fetchNotifications());
            setOpenCheckout(false);
            setActive(false);
            Notification(`You purchased the Land`, true);
          }).catch((error) => {
            let msg = (typeof error === 'object') ? error.message : error;
            setActive(false);
            Notification(msg, false);
          });
        }
        else {
          TileService.mintTile(activeParcel?.id, {isVault: isVault, coupon: coupon}).then(async (res) => {
            setActiveParcel(null);
            const data = await TileService.fetchTile(activeParcel?.id);
            setActiveParcel(data);
            dispatch(actions.fetchNotifications());
            setOpenCheckout(false);
            setActive(false);
            Notification(`You purchased the Land`, true);
          }).catch((error) => {
            let msg = (typeof error === 'object') ? error.message : error;
            setActive(false);
            Notification(msg, false);
          });
        }
      }
    }
    else if (status === 'error') {
      if (currentSM === 'cancel') {
        setActive(false);
        Notification('Error occurred while cancel listing this Tile.', false);
      }
      else if (currentSM === 'list') {
        setActive(false);
        Notification('Error occurred while add listing this Tile.', false);
      }
      else if (currentSM === 'approve') {
        setActive(false);
        Notification('Error occurred while approve this Tile.', false);
      }
      else if (currentSM === 'eiv-approve') {
        setActive(false);
        Notification('Failed to approve USDC token.', false);
      }
      else if (currentSM === 'buy-item') {
        setActive(false);
        Notification('Error occurred while purchasing this Tile.', false);
      }
    }
  }, [status]);

  useEffect(() => {
    if (isSuccess) {
      setHash(data);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      if (currentSM === 'cancel') {
        setActive(false);
        Notification('Error occurred while cancel listing this Tile.', false);
      }
      else if (currentSM === 'list') {
        setActive(false);
        Notification('Error occurred while add listing this Tile.', false);
      }
      else if (currentSM === 'approve') {
        setActive(false);
        Notification('Error occurred while approve this Tile.', false);
      }
      else if (currentSM === 'eiv-approve') {
        setActive(false);
        Notification('Failed to approve USDC token.', false);
      }
      else if (currentSM === 'buy-item') {
        setActive(false);
        Notification('Error occurred while purchasing this Tile.', false);
      }
    }
  }, [isError]);

  const fetchCoupon = (value, amount) => {
    setFetchingCoupon(true);
    if(value === '' || value === null) {
      setCouponError(false);
      setCoupon('');
      setCouponPrice(amount);
      setCouponSet(false);
      setFetchingCoupon(false);
      setCouponOverdue(false);
    }
    else {
      CouponService.fetchCoupon(value).then(res => {
        if (res) {
          if (res === true) {
            setCouponError(true);
            setCouponSet(false);
            setCoupon('');
            setCouponPrice(amount);
            setFetchingCoupon(false);
            setCouponOverdue(true);
          }
          else {
            setCouponError(false);
            setCouponSet(true);
            setCoupon(res.id);
            setCouponPrice(parseInt(amount * (1 - res.discount / 100)));
            setFetchingCoupon(false);
            setCouponOverdue(false);
          }
        } else {
          setCouponError(true);
          setCoupon('');
          setCouponPrice(amount);
          setCouponSet(false);
          setFetchingCoupon(false);
          setCouponOverdue(false);
        }
      });
    }
  }

  const [debounceFetchAddress] = useState(() => debounce(fetchAddress, 1000));
  const [debounceFetchCoupon] = useState(() => debounce(fetchCoupon, 1000));

  const auth = async () => {
    // if (window.ethereum) {
      // login(dispatch, setActions.setLoading, setActions.setUser, history);
      open();
      setShowModal(false);
    // }
  };

  const buyMintNFT = async (vault) => {
    setIsVault(vault);
    // const enabled = await enableWeb3();
    // if (!enabled) {
    //   Notification('Waiting for metamask sign in.', false);
    //   return
    // }
    setActive(true);
    setLoadingMessage("Please wait while you purchase.")
    // const account = signedUser.metamask;
    try {
      // const amount = Web3.utils.toBN(couponPrice).add(Web3.utils.toBN(Web3.utils.toWei('0.001', 'mwei')));
      const amount = Web3.utils.toBN(couponPrice).add(Web3.utils.toBN(Web3.utils.toWei('0', 'mwei')));
      if(!vault) {
        // await Allowance(eivContract, account, marketplaceAddress, amount);
        setCurrentSM('mint-approve');
        writeContract({ 
          abi: erc20Abi.abi,
          address: eivAddress,
          functionName: 'approve',
          args: [
            marketplaceAddress,
            amount,
          ],
        });
      }
      else {
        if(activeParcel.TileGroup) {
          await TileService.mintTileGroup(activeParcel?.TileGroup?.id, {isVault: vault, coupon: coupon});
        }
        else {
          await TileService.mintTile(activeParcel?.id, {isVault: vault, coupon: coupon});
        }
  
        setActiveParcel(null);
        const data = await TileService.fetchTile(activeParcel?.id);
        setActiveParcel(data);
        dispatch(actions.fetchNotifications());
        setOpenCheckout(false);
        setActive(false);
        Notification(`You purchased the Land`, true);
      }
    } catch (error) {
      let msg = (typeof error === 'object') ? error.message : error;
      setActive(false);
      Notification(msg, false);
    }
  }

  const buyNFT = async (vault) => {
    setActive(true);
    setIsVault(vault);
    setLoadingMessage("Please wait while you purchase.");
    // const enabled = await enableWeb3();
    // if (!enabled) {
    //   Notification('Waiting for metamask sign in.', false);
    //   return
    // }
    // const account = signedUser.metamask;
    try {
      let amount = 0;
      if (!vault) {
        amount = couponPrice;
        // await Allowance(eivContract, account, marketplaceAddress, amount);
        setCurrentSM('eiv-approve');
        writeContract({ 
          abi: erc20Abi.abi,
          address: eivAddress,
          functionName: 'approve',
          args: [
            marketplaceAddress,
            amount,
          ],
        });
      }
      else {
        // const tx = await marketplaceContract.methods.buyItem(
        //   activeParcel.TileGroup ? activeParcel?.TileGroup?.tokenId : activeParcel?.tokenId, true, vault, coupon ? true : false, coupon).send({from: account});
        setCurrentSM('buy-item');
        writeContract({ 
          abi: MarketplaceAbi.abi,
          address: marketplaceAddress,
          functionName: 'buyItem',
          args: [
            activeParcel.TileGroup ? activeParcel?.TileGroup?.tokenId : activeParcel?.tokenId,
            true,
            vault,
            coupon ? true : false,
            coupon
          ],
        });
      }
    } catch (error) {
      let msg = (typeof error === 'object') ? error.message : error;
      setActive(false);
      Notification(msg, false);
    }
  }

  const handleBuyNow = () => {
    if (signedUser.id) {
      dispatch(setActions.setLoading(true));
      AuthService.sessionCheck().then((res) => {
        dispatch(setActions.setLoading(false));
        snapshotContract.methods.getInfo(signedUser.metamask).call().then(result => {
          setOpenCheckout(true);
          setCoupon("");
          setVaultChecked(false);
          setVaultAmount(parseFloat(Web3.utils.fromWei(result[0], 'mwei')));

          snapshotContract.methods.EQUITY_INITIAL_SUPPLY().call().then(initialSupply => {
            const nftPrice = BigNumber.from(activeParcel?.price);
            const balance = BigNumber.from(result[0]);
            const exchangeRateScaled = nftPrice.mul(exchangeRateScale).div(exchangeRate);
            const subtracted = balance.sub(exchangeRateScaled);
            const divided = parseFloat(Web3.utils.fromWei(subtracted.toString(), 'mwei')) * 100 / parseFloat(Web3.utils.fromWei(initialSupply, 'mwei'));
            setVaultPercent(divided);
          })
        });
      })
      .catch(err => {
        dispatch(setActions.setLoading(false));
        if(err.response?.status == '401') {
          dispatch(actions.setIsTimeout(true));
        }
      })
    } else {
      setShowModal(true);
    }
  };
  
  const handleAddListing = async () => {
    if (signedUser.id) {
      dispatch(setActions.setLoading(true));
      setLoadingMessage("Please wait while you list your NFT for sale.")
      AuthService.sessionCheck().then(async (res) => {
        dispatch(setActions.setLoading(false));
        setOpenAddListing(true);
        setOpenCheckoutbid(false);
        setOpenCheckout(false);
        setOpenWithdrawBid(false);
      })
      .catch(err => {
        dispatch(setActions.setLoading(false));
        if(err.response?.status == '401') {
          dispatch(actions.setIsTimeout(true));
        }
      })
    } else {
      setShowModal(true);
    }
  }

  const handleBid = async () => {
    if (signedUser.id) {
      setOpenCheckoutbid(true);
      setOpenCheckout(false);
      setOpenWithdrawBid(false);
      setOpenAddListing(false);
    } else {
      setShowModal(true);
    }
  }

  const handleWithdrawBid = async () => {
    if (signedUser.id) {
      setOpenWithdrawBid(true);
      setOpenCheckoutbid(false);
      setOpenCheckout(false);
      setOpenAddListing(false);
    } else {
      setShowModal(true);
    }
  }

  const admint = async () => {
    setActive(true);
    setLoadingMessage("Please wait while you admint.");
    try {
      if(activeParcel.TileGroup) {
        await TileService.admintTileGroup(activeParcel?.TileGroup?.id, {"isAdmint": true});
      }
      else {
        await TileService.admintTile(activeParcel?.id, {"isAdmint": true});
      }

      dispatch(actions.fetchNotifications());
      setActiveParcel(null);
      const data = await TileService.fetchTile(activeParcel?.id);
      setActiveParcel(data);
      setOpenCheckout(false);
      setActive(false);
      Notification(`You adminted a ${activeParcel?.TileGroup ? 'Land Group' : 'Land'}.`, true);

    } catch (error) {
      let msg = (typeof error === 'object') ? error.message : error;
      setActive(false);
      Notification(msg, false);
    }
  }

  const sendManualTransfer = async () => {
    dispatch(setActions.setLoading(true));
    AuthService.sessionCheck().then(async (res) => {
      dispatch(setActions.setLoading(false));
      // const enabled = await enableWeb3();
      // if (!enabled) {
      //   Notification('Waiting for metamask sign in.', false);
      //   return
      // }
      setActive(true);
      try {
        const metadata = {
          'receiverAddress': transferAddress.metamask,
          'receiverId': transferAddress.id,
        };

        if(activeParcel.TileGroup) {
          await TileService.transferTileGroup(activeParcel?.TileGroup?.id, metadata);
        }
        else {
          await TileService.transferTile(activeParcel?.id, metadata);
        }

        setShowTransferNft(false)
        setActive(false);

        dispatch(actions.fetchNotifications());
        setActiveParcel(null);
        const data = await TileService.fetchTile(activeParcel?.id);
        setActiveParcel(data);

        Notification(`Your ${activeParcel?.TileGroup ? 'Land Group' : 'Land'} #${activeParcel.TileGroup ? activeParcel?.TileGroup?.tokenId : activeParcel?.tokenId} has been transferred successfully.`, true);
      } catch (error) {
        let msg = (typeof error === 'object') ? error.message : error;
        setActive(false);
        setShowTransferNft(false)
        Notification(msg, false);
      }
    })
    .catch(err => {
      dispatch(setActions.setLoading(false));
      if(err.response?.status == '401') {
        dispatch(actions.setIsTimeout(true));
      }
    })
  }
	
  const handleSearchAddress = (value) => {
    setSearchAddress(value);
    debounceFetchAddress(value);
  }

  const cancelListing = async () => {
    dispatch(setActions.setLoading(true));
    AuthService.sessionCheck().then(async (res) => {
      dispatch(setActions.setLoading(false));
      setOpenWithdrawBid(false);
      setActive(true);
      // const enabled = await enableWeb3();
      // if (!enabled) {
      //   Notification('Waiting for metamask sign in.', false);
      //   return
      // }

      try {
        // const account = signedUser.metamask;
        setCurrentSM('cancel');
        writeContract({ 
          abi: MarketplaceAbi.abi,
          address: marketplaceAddress,
          functionName: 'cancel',
          args: [
            activeParcel.TileGroup ? activeParcel?.TileGroup?.tokenId : activeParcel?.tokenId
          ],
        });
        // const txRes = await marketplaceContract.methods.cancel(activeParcel.TileGroup ? activeParcel?.TileGroup?.tokenId : activeParcel?.tokenId).send({from: account});
      } catch (error) {
        let msg = (typeof error === 'object') ? error.message : error;
        setActive(false);
        Notification(msg, false);
        return false
      }
    })
    .catch(err => {
      dispatch(setActions.setLoading(false));
      if(err.response?.status == '401') {
        dispatch(actions.setIsTimeout(true));
      }
    })
  }

	const withdrawBid = async () => {
  }

  const addListing = async () => {
    setActive(true);
    // const enabled = await enableWeb3();
    // if (!enabled) {
    //   Notification('Waiting for metamask sign in.', false);
    //   return
    // }
    // const account = signedUser.metamask;
    try {
      const amount = Web3.utils.toWei(sellPrice, 'gwei') / 1000;
      setCurrentSM('approve');
      writeContract({ 
        abi: NFTAbi.abi,
        address: nftAddress,
        functionName: 'approve',
        args: [
          marketplaceAddress,
          activeParcel.TileGroup ? activeParcel?.TileGroup?.tokenId : activeParcel?.tokenId
        ],
      });
      // await nftContract.methods.approve(marketplaceAddress, activeParcel.TileGroup ? activeParcel?.TileGroup?.tokenId : activeParcel?.tokenId).send({from: account});
      // const txRes = await marketplaceContract.methods.list(activeParcel.TileGroup ? activeParcel?.TileGroup?.tokenId : activeParcel?.tokenId, amount).send({from: account});
    } catch (error) {
      let msg = (typeof error === 'object') ? error.message : error;
      setActive(false);
      Notification(msg, false);
      return false
    }
  }

  const handleSearchCoupon = (value) => {
    debounceFetchCoupon(value, parseFloat(activeParcel?.TileGroup ? activeParcel?.TileGroup?.price : activeParcel?.price));
  }

  const handleManualRegister = async () => {
    setActive(true);
    const tx = JSON.parse(activeParcel.TileGroup ? activeParcel?.TileGroup?.txHash : activeParcel.txHash);
    if (tx.type === 0) {
      const metadata = {
        'txHash': tx.hash,
        'tokenId': activeParcel.TileGroup ? activeParcel?.TileGroup?.tokenId : activeParcel.tokenId,
        'isVault': tx.isVault,
        'coupon': tx.coupon
      }
      if(activeParcel?.TileGroup) {
        await TileService.buyTileGroup(activeParcel?.TileGroup?.id, metadata);
      } else {
        await TileService.buyTile(activeParcel?.id, metadata);
      }
    } else if (tx.type === 1) {
      const metadata = {
        'price': tx.price,
        'txHash': tx.hash
      };

      if(activeParcel.TileGroup) {
        await TileService.addTileGroupListing(activeParcel?.TileGroup?.id, metadata);
      }
      else {
        await TileService.addTileListing(activeParcel?.id, metadata);
      }
    } else if (tx.type === 2) {
      const metadata = {
        'txHash': tx.hash
      };

      if(activeParcel.TileGroup) {
        await TileService.cancelTileGroupListing(activeParcel?.TileGroup?.id, metadata);
      }
      else {
        await TileService.cancelTileListing(activeParcel?.id, metadata);
      }
    }
    setActiveParcel(null);
    let data = await TileService.fetchTile(activeParcel?.id);
    setActiveParcel(data);
    dispatch(actions.fetchNotifications());
    setActive(false);
  }

  return (
    <React.Fragment>
      <div 
        style={{
          width: 300,
          borderRadius: 15,
          boxShadow: '0px 4px 40px rgba(1, 4, 24, 0.1)',
          background: 'white',
          position: 'fixed',
          zIndex: 20,
          top: 100,
          right: 20,
          overflow: 'hidden'
        }}
      >
        <div style={{position: 'relative'}}>
        { 
          activeParcel.TileGroup ? (activeParcel?.TileGroup?.Owner?.id ===  signedUser?.id ? 
            <div style={{position: 'absolute', top: 0, left: 0, background: 'rgb(167, 6, 170)', borderRadius: '0px 0px 15px 0px', padding: 5, width: 120, textAlign: 'center', color: 'white', fontSize: 15, fontWeight: 600}}>My Land</div> 
           : <div style={{position: 'absolute', top: 0, left: 0, background: LAND_PACK, borderRadius: '0px 0px 15px 0px', padding: 5, width: 120, textAlign: 'center', color: 'white', fontSize: 15, fontWeight: 600}}>Land Pack</div>)
          : activeParcel?.ownerId === signedUser?.id ?
            <div style={{position: 'absolute', top: 0, left: 0, background: 'rgb(167, 6, 170)', borderRadius: '0px 0px 15px 0px', padding: 5, width: 120, textAlign: 'center', color: 'white', fontSize: 15, fontWeight: 600}}>My Land</div> 
            :(activeParcel?.isInfrastructure ?
              <div style={{position: 'absolute', top: 0, left: 0, background: MAP_INFRA_COLOR, borderRadius: '0px 0px 15px 0px', padding: 5, width: 120, textAlign: 'center', color: 'white', fontSize: 15, fontWeight: 600}}>Infrastructure</div>
              : (
                activeParcel.isForSale ? (
                  activeParcel?.User?.metamask === process.env.REACT_APP_COMPANY_WALLET_ADDRESS.toLowerCase() 
                    ? 
                      activeParcel?.tierId === 5 
                        ? <div style={{position: 'absolute', top: 0, left: 0, background: TIER5, borderRadius: '0px 0px 15px 0px', padding: 5, width: 120, textAlign: 'center', color: 'white', fontSize: 15, fontWeight: 600}}>VVIP</div>
                        : <div style={{position: 'absolute', top: 0, left: 0, background: activeParcel?.tierId === 1 ? TIER1 : (activeParcel?.tierId === 2 ? TIER2 : (activeParcel?.tierId === 3 ? TIER3 : (activeParcel?.tierId === 4 ? TIER4 : TIER5))), borderRadius: '0px 0px 15px 0px', padding: 5, width: 120, textAlign: 'center', color: activeParcel?.tierId === 4 ? 'black' : 'white', fontSize: 15, fontWeight: 600}}>{activeParcel?.Tier?.name}</div>
                    :
                    <div style={{position: 'absolute', top: 0, left: 0, background: 'rgb(0, 102, 0)', borderRadius: '0px 0px 15px 0px', padding: 5, width: 120, textAlign: 'center', color: 'white', fontSize: 15, fontWeight: 600}}>For Sale</div> 
                )
                : ( activeParcel?.User?.metamask === process.env.REACT_APP_COMPANY_WALLET_ADDRESS.toLowerCase() ? 
                  (activeParcel?.tileType === MAP_LAND_LABEL 
                    ? <div style={{position: 'absolute', top: 0, left: 0, background: '#e6ddc8', borderRadius: '0px 0px 15px 0px', padding: 5, width: 120, textAlign: 'center', color: 'black', fontSize: 15, fontWeight: 600}}>Land</div>
                    : <div style={{position: 'absolute', top: 0, left: 0, background: '#289be2', borderRadius: '0px 0px 15px 0px', padding: 5, width: 120, textAlign: 'center', color: 'white', fontSize: 15, fontWeight: 600}}>Ocean</div>) 
                  :
                  <div style={{position: 'absolute', top: 0, left: 0, background: 'rgb(167, 6, 170)', borderRadius: '0px 0px 15px 0px', padding: 5, width: 120, textAlign: 'center', color: 'white', fontSize: 15, fontWeight: 600}}>Sold</div>
                )
              )
            )
        }
          <IconButton aria-label="delete" onClick={() => setActiveParcel(null)} style={{position: 'absolute', top: 0, right: 0, background: 'transparent'}}>
            <HighlightOffIcon />
          </IconButton>
          {
            activeParcel?.vvip && <p className='tile-map-parcel-detail-vvip-label'>VVIP</p>
          }
          {activeParcel?.image ? 
            <img src={api.ipfsURL + activeParcel?.image} className="img-fluid img-rounded mb-sm-30" alt="" style={{width: 300}}/>
            : <img src={LandPlaceholder} className="img-fluid img-rounded mb-sm-30" alt="" style={{width: 300, height: 170}}/>
          }
          <div style={{margin: 20}}>
            {activeParcel.TileGroup ? <>
              <p style={{fontSize: 20, color: 'black', fontWeight: 600}}>{activeParcel?.TileGroup.type} #{activeParcel?.tileGroupId}</p>
              <p style={{color: 'black'}}>{activeParcel?.TileGroup.name}</p>
              {activeParcel?.TileGroup?.isForSale ? <p style={{color: 'black'}}>Price in USDC : {activeParcel?.TileGroup.price && Web3.utils.fromWei(activeParcel?.TileGroup.price, 'gwei') * 1000} USDC</p> : <p style={{color: 'black'}}>Not For Sale</p>}
              <p style={{color: 'black'}}>Description</p>
              <TextField
                multiline
                rows={5}
                disabled
                value={activeParcel?.TileGroup.description}
                style={{width: '-webkit-fill-available', marginBottom: 10}}
              />
            </>
            :<>
              <p style={{fontSize: 20, color: 'black', fontWeight: 600}}>{activeParcel?.tileType} #{activeParcel?.id}</p>
              <p style={{color: 'black'}}>X = {activeParcel?.posX}, Y = {activeParcel?.posY}</p>
              <p style={{color: 'black'}}>Type: {activeParcel?.tileGroupId ? 'Group' : 'Unique'}</p>
              { activeParcel?.ownerId === signedUser?.id ?
                <p style={{color: 'black'}}>You own this parcel</p> 
                :(activeParcel?.isInfrastructure ?
                  <p style={{color: 'black'}}>Not for Sale</p> 
                  : (
                    activeParcel.isForSale 
                      ? <p style={{color: 'black'}}>Price in USDC : {activeParcel?.price ? Web3.utils.fromWei(activeParcel?.price, 'gwei') * 1000 : Web3.utils.fromWei(activeParcel?.Tier?.price, 'gwei') * 1000} USDC</p>
                      : <p style={{color: 'black'}}>Not for Sale</p>
                  )
                )
              }
              {/* <p style={{color: 'black'}}>Price in USDC : {activeParcel?.price && Web3.utils.fromWei(activeParcel?.price, 'gwei') * 1000} USDC</p> */}
              {activeParcel?.description && <>
                <p style={{color: 'black'}}>Description</p>
                <TextField
                    multiline
                    rows={5}
                    disabled
                    value={activeParcel?.description}
                    style={{width: '-webkit-fill-available', marginBottom: 10}}
                />
              </>}
            </>}
            {activeParcel.TileGroup ? <>
              {activeParcel?.TileGroup?.txHash ? <>
                  {txProcessing ? <>{activeParcel.TileGroup && signedUser.id &&
                    <button
                      className='btn-main lead mb-5 mr15'
                      onClick={handleManualRegister}
                    >
                    {t("Register Transaction")}
                    </button>
                  }</> : <>
                    <p style={{color:'red'}}>Transaction is processing with other user.</p>
                  </>}
                </>
                : <>
                {activeParcel?.TileGroup?.isForSale && signedUser?.id !== activeParcel?.TileGroup?.Owner?.id && <button className='btn-main lead mt-2' onClick={() => handleBuyNow()}>{"Buy Now"}</button>}
                {signedUser?.id !== activeParcel?.TileGroup?.Owner?.id && activeParcel?.TileGroup?.isForBid && isBidAvailable &&
                  <button
                    className='btn-main lead mt-2'
                    onClick={handleBid}
                  >
                  {t("AddBid")}
                  </button>
                }
                {signedUser?.id === activeParcel?.TileGroup?.Owner?.id && activeParcel?.TileGroup?.isForSale && activeParcel?.TileGroup?.minted && <button
                    className='btn-main lead mt-2'
                    onClick={cancelListing}
                  >Do not Sell</button>}
                {signedUser?.id === activeParcel?.TileGroup?.Owner?.id && !activeParcel?.TileGroup?.isForSale && activeParcel?.TileGroup?.minted && <button
                    className='btn-main lead mt-2'
                    onClick={handleAddListing}
                  >Want to Sell</button>}

                {signedUser?.id === activeParcel?.TileGroup?.Owner?.id && activeParcel?.TileGroup?.minted && signedUser.roleId === EROLES.ADMIN && !activeParcel?.TileGroup?.isForSale && signedUser?.metamask === walletAddress.toLowerCase() && <button
                      className='btn-main lead mt-2'
                      onClick={() => setShowTransferNft(true)}
                    >Transfer this NFT</button>}

                {!isBidAvailable &&
                  <button
                    className='btn-main lead mt-2'
                    onClick={handleWithdrawBid}
                  >
                    Withdraw your Bid
                  </button>
                }
                {signedUser.metamask == walletAddress.toLowerCase() && !activeParcel?.TileGroup?.minted && <button className='btn-main lead mb-5 mr15' onClick={admint}>Admint</button>}
              </>}
            </> : <>
              {activeParcel.txHash ? <>
                  {txProcessing ? <>{activeParcel && signedUser.id &&
                    <button
                      className='btn-main lead mb-5 mr15'
                      onClick={handleManualRegister}
                    >
                    {t("Register Transaction")}
                    </button>
                  }</> : <>
                    <p style={{color:'red'}}>Transaction is processing with other user.</p>
                  </>}
                </>
                : <>
                {(activeParcel?.isForSale || activeParcel?.TileGroup?.isForSale) && signedUser?.id !== activeParcel?.ownerId && <button className='btn-main lead mt-2' onClick={() => handleBuyNow()}>{"Buy Now"}</button>}
                {signedUser?.id !== activeParcel?.ownerId && activeParcel?.isForBid && isBidAvailable &&
                  <button
                    className='btn-main lead mt-2'
                    onClick={handleBid}
                  >
                  {t("AddBid")}
                  </button>
                }
                {signedUser?.id === activeParcel?.ownerId && activeParcel?.isForSale && activeParcel?.minted && <button
                    className='btn-main lead mt-2'
                    onClick={cancelListing}
                  >Do not Sell</button>}

                {signedUser?.id === activeParcel?.ownerId && !activeParcel?.isForSale && activeParcel?.minted && <button
                    className='btn-main lead mt-2'
                    onClick={handleAddListing}
                  >Want to Sell</button>}

                {signedUser?.id === activeParcel?.ownerId && activeParcel.minted && signedUser.roleId === EROLES.ADMIN && !activeParcel?.isForSale && signedUser?.metamask === walletAddress.toLowerCase() && <button
                      className='btn-main lead mt-2'
                      onClick={() => setShowTransferNft(true)}
                    >Transfer this NFT</button>}

                {!isBidAvailable &&
                  <button
                    className='btn-main lead mt-2'
                    onClick={handleWithdrawBid}
                  >
                    Withdraw your Bid
                  </button>
                }
              </>}
            </>}
            {/* {signedUser?.id && signedUser?.id === activeParcel?.ownerId && <button className='btn-main lead mb-5 mr15' onClick={handleManage}>{"Manage Now"}</button>} */}
            <a target="_blank" href="https://wiki.earniverse.io/docs" style={{color: 'blue'}} rel="noreferrer"><u>Questions ? Visit our wiki pages</u></a>
          </div>
        </div>
        <Dialog
          open={showModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className='alert-modal'
        >
          <div className={classes.root}>
            <div className={classes.topBlock}>
              <CheckCircleOutlinedIcon className={classes.icon} />
            </div>  
            <div className={classes.bottomBlock}>
              <Typography className={classes.title}>
                  {t("You need to connect your wallet first!")}
              </Typography>
              <div className={classes.buttonBlock}>
                <button
                  className="btn-main"
                  onClick={()=>setShowModal(false)}
                >
                  {t("Close")} 
                </button>
                <button
                  className="btn-main"
                  onClick={() => auth()}
                >
                  {t("Connect")}
                </button>
              </div>
            </div>
          </div>  
        </Dialog>
        {openCheckout && (
        <div className='checkout'>
        <div className='maincheckout'>
          <button
            className='btn-close'
            onClick={() => setOpenCheckout(false)}
          >
            x
          </button>
          <div className='heading'>
            <h3>{t("Checkout")}</h3>
          </div>
          {activeParcel.TileGroup ? 
          <>
          {Web3.utils.fromWei(activeParcel?.TileGroup?.price.toString(), 'mwei') * exchangeRateScale / exchangeRate < vaultAmount && isVaultChecked === false ?
            <>
              <p>
                {t("ToPurchase")}{' '}
                <span className='bold'>Land Group{' '}</span>
                <span>{t("From")}</span>
                <span className='bold'> {activeParcel.TileGroup && activeParcel?.TileGroup?.Owner && activeParcel?.TileGroup?.Owner?.username}</span>
                <span> With <span className='bold'>your holdings</span>.</span>
              </p>
              <div className='mt-3'>
                <span>Would you like to buy this Land Group with your holdings. Your current share will be updated to { vaultPercent }%</span>
              </div>
              <div className='heading'>
              </div>
              <div className='d-flex justify-content-center'>
                <button className='btn-main lead m-2' onClick={() => activeParcel?.TileGroup?.minted ? buyNFT(true) : buyMintNFT(true)}>{'Buy (Holdings)'}</button>
                <button className='btn-main lead m-2' onClick={() => setVaultChecked(true)}>{t("Buy (USDC)")}</button>
              </div>
            </> :
            <>
              <p>
                {t("ToPurchase")}{' '}
                <span className='bold'>Land Group{' '}</span>
                <span>{t("From")}</span>
                <span className='bold'> {activeParcel.TileGroup && activeParcel?.TileGroup?.Owner && activeParcel?.TileGroup?.Owner?.username}</span>
                <span> with <span className='bold'>USDC</span>.</span>
              </p>
              <div className='heading mt-3'>
              </div>
              {isCouponSet && coupon && <>
                <div className='heading'>
                  <p>{t("Price")}</p>
                  <div className='subtotal'>{Web3.utils.fromWei((activeParcel?.TileGroup ? activeParcel?.TileGroup?.price : activeParcel?.price).toString(), 'mwei')} USDC</div>
                </div>
                <div className='heading'>
                  <p>{t("Discount")}</p>
                  <div className='subtotal'>{Web3.utils.fromWei(((activeParcel?.TileGroup ? activeParcel?.TileGroup?.price : activeParcel?.price) - couponPrice).toString(), 'mwei')} USDC</div>
                </div>
              </>}
              <div className='heading'>
                <p>{t("YouWillPay")}</p>
                <div className='subtotal'>{Web3.utils.fromWei(couponPrice.toString(), 'mwei')} USDC</div>
              </div>
              {activeParcel?.TileGroup?.Owner?.metamask === process.env.REACT_APP_COMPANY_WALLET_ADDRESS.toLowerCase() && <div className='heading mt-3'>
                <div className='coupon'>
                  <p>Coupon Code (optional)</p>
                  <input
                    type='text'
                    name='coupon'
                    id='coupon'
                    className='form-control'
                    onChange={(event) => handleSearchCoupon(event.target.value)}
                  />
                  {isCouponError && <p style={{color:'red'}}>{isCouponOverdue ? 'This Coupon Code is expired.' : 'Invalid Coupon.'}</p>}
                </div>
              </div>}
              <button disabled={isFetchingCoupon} className='btn-main lead mb-5' onClick={() => activeParcel?.TileGroup?.minted ? buyNFT(false) : buyMintNFT(false)}>{t("BuyNow")}</button>
            </>}
          </>
          :
          <>
            {Web3.utils.fromWei(activeParcel?.price, 'mwei') * exchangeRateScale / exchangeRate < vaultAmount && isVaultChecked === false ?
            <>
              <p>
                {t("ToPurchase")}{' '}
                <span className='bold'>{activeParcel && activeParcel.tileType}{' '}{`X=${activeParcel.posX}, Y=${activeParcel.posY}, Z=${activeParcel.posZ}`}{' '}</span>
                <span>{t("From")}</span>
                <span className='bold'> {activeParcel && activeParcel.User && activeParcel.User.username}</span>
                <span> With <span className='bold'>your holdings</span>.</span>
              </p>
              <div className='mt-3'>
                <span>Would you like to buy this Land with your holdings. Your current share will be updated to { vaultPercent }%</span>
              </div>
              <div className='heading'></div>
              <div className='d-flex justify-content-center'>
                <button className='btn-main lead m-2' onClick={() => activeParcel?.minted ? buyNFT(true) : buyMintNFT(true)}>{'Buy (Holdings)'}</button>
                <button className='btn-main lead m-2' onClick={() => setVaultChecked(true)}>{t("Buy (USDC)")}</button>
              </div>
            </> :
            <>
              <p>
                {t("ToPurchase")}{' '}
                <span className='bold'>{activeParcel && activeParcel.tileType}{' '}{`X=${activeParcel.posX}, Y=${activeParcel.posY}, Z=${activeParcel.posZ}`}{' '}</span>
                <span>{t("From")}</span>
                <span className='bold'> {activeParcel && activeParcel.User && activeParcel.User.username}</span>
                <span> with <span className='bold'>USDC</span>.</span>
              </p>
              <div className='heading mt-3'>
                {/* <p>{t("YourBlance")}</p> */}
                {/* <div className='subtotal'>10.67856 ETH</div> */}
                {/* <div className='subtotal'> {balance ? balance.formatted : ''}</div> */}
              </div>
              {/* <div className='heading'>
                <p>Price: </p>
                <div className='subtotal'>{nft && nft.price && Web3.utils.fromWei(nft.price, 'mwei') + ' EIV / ' + (Web3.utils.fromWei(nft.price, 'mwei') * priceRate).toFixed(2) + ' USDT'}</div>
              </div> */}
              {isCouponSet && coupon && <>
                <div className='heading'>
                  <p>{t("Price")}</p>
                  <div className='subtotal'>{Web3.utils.fromWei(activeParcel?.price.toString(), 'mwei')} USDC</div>
                </div>
                <div className='heading'>
                  <p>{t("Discount")}</p>
                  <div className='subtotal'>{Web3.utils.fromWei((activeParcel?.price - couponPrice).toString(), 'mwei')} USDC</div>
                </div>
              </>}
              <div className='heading'>
                <p>{t("YouWillPay")}</p>
                <div className='subtotal'>{Web3.utils.fromWei(couponPrice.toString(), 'mwei')} USDC</div>
              </div>
              {activeParcel?.User?.metamask === process.env.REACT_APP_COMPANY_WALLET_ADDRESS.toLowerCase() && <div className='heading mt-3'>
                <div className='coupon'>
                  <p>Coupon Code (optional)</p>
                  <input
                    type='text'
                    name='coupon'
                    id='coupon'
                    className='form-control'
                    onChange={(event) => handleSearchCoupon(event.target.value)}
                  />
                  {isCouponError && <p style={{color:'red'}}>{isCouponOverdue ? 'This Coupon Code is expired.' : 'Invalid Coupon.'}</p>}
                </div>
              </div>}
              <button disabled={isFetchingCoupon} className='btn-main lead mb-5' onClick={() => activeParcel?.minted ? buyNFT(false) : buyMintNFT(false)}>{t("BuyNow")}</button>
              {/* {
                // eslint-disable-next-line
                <p>The current price is calculated as per USDT / EIV Exchange Rate at the time of loading and will be available for the next 60 seconds. <a onClick={() => fetchPriceRate()}><u className={classes.refreshLink}>Refresh now</u></a></p>
              } */}
            </>}
          </>}
          </div>
        </div>
        )}
        {openCheckoutbid && (
          <div className='checkout'>
            <div className='maincheckout'>
              <button
                className='btn-close'
                onClick={() => setOpenCheckoutbid(false)}
              >
                x
              </button>
              <div className='heading'>
                <h3>{t("PlaceBid")}</h3>
              </div>
              <p>
              {t("ToPurchase")}{' '}
                <span className='bold'>{activeParcel && activeParcel.posX + ', ' + activeParcel.posY + ', ' + activeParcel.posZ}</span>
              </p>
              <p>
                <span>Price: {activeParcel && Web3.utils.fromWei(activeParcel.price, 'gwei') * 1000} USDC</span>
              </p>
              <div className='detailcheckout mt-4'>
                <div className='listcheckout'>
                  <h6>{t("YourBid")} (EIV)</h6>
                  <input type='number' step={0.01} className='form-control' onChange={(event) => setPrice(event.target.value)} />
                  {isPriceError && <p style={{color:'red'}}>Bid price should be higher than {Web3.utils.fromWei(activeParcel.price, 'gwei') * 1000} EIV.</p>}
                </div>
              </div>
              <div className='heading mt-3'>
              </div>
              <div className='heading'>
                <p>{t("YouWillPay")}</p>
              </div>
            </div>
          </div>
        )}
        {openWithdrawBid && (
          <div className='checkout'>
            <div className='maincheckout'>
              <button
                className='btn-close'
                onClick={() => setOpenWithdrawBid(false)}
              >
                x
              </button>
              <div className='heading'>
                <h3>Withdraw your bid</h3>
              </div>
              <p>
                <span>Price: {activeParcel && Web3.utils.fromWei(bidAmount, 'gwei') * 1000} USDC</span>
              </p>
              <div className='heading mt-3'>
                {/* <p>{t("YourBlance")}</p>
                <div className='subtotal'>{balance ? balance.formatted : ''}</div> */}
              </div>
              <div className='heading'>
                <p>You will be paid</p>
                <div className='subtotal'>{activeParcel && Web3.utils.fromWei(bidAmount, 'gwei') * 1000} EIV</div>
              </div>
              <button className='btn-main lead mb-5' onClick={withdrawBid}>Withdraw</button>
            </div>
          </div>
        )}
        {openAddListing && activeParcel && (
          <div className='checkout'>
            <div className='maincheckout'>
              <button
                className='btn-close'
                onClick={() => setOpenAddListing(false)}
              >
                x
              </button>
              <div className='heading'>
                <h3>Sell My NFT</h3>
              </div>
              <p>
                <span className='bold'>{activeParcel && activeParcel?.Continent?.name}: {activeParcel && activeParcel.posX + ', ' + activeParcel.posY + ', ' + activeParcel.posZ}</span>
              </p>
              <div className='detailcheckout mt-4'>
                <div className='listcheckout'>
                  <div className="de_tab tab_methods">
                    <ul className="de_nav">
                      <li className={isForBid ? null : "active"} onClick={() => setIsForBid(false)}><span><i className="fa fa-tag"></i>{t("FixedPrice")}</span>
                      </li>
                      {/* <li className={isForBid ? "active" : null} onClick={() => setIsForBid(true)}><span><i className="fa fa-users"></i>{t("OpenForBids")}</span>
                      </li> */}
                    </ul>
                  </div>
                  <div className='price_container' style={{marginTop: 20}}>
                    <h6 style={{lineHeight: 0}}>Set the price</h6>
                  </div>
                  <input
                    type='text'
                    name='buy_now_qty'
                    id='buy_now_qty'
                    className='form-control'
                    style={{marginBottom: 5}}
                    onChange={(event) => setSellPrice(event.target.value)}
                  />
                  <div className='price_container'>
                    <p>{isForBid 
                        ? 'Set the minimum bid price for this NFT :'
                        : 'Set the selling price for the NFT :'}</p>
                    <p>
                      {isForBid ? (sellPrice ? (sellPrice + ' USDC') : '') : (sellPrice ? (sellPrice + ' USDC') : '')}
                    </p>
                  </div>
                </div>
              </div>
              <div className='heading mt-3'>
              </div>
              <div className='heading'>
                <p>Selling a LAND is subject to Royalties as per our <a href="http://earniverse.io/terms-of-service" target='_blank'><u>Terms of Service</u></a> ({royalty}% Marketplace Royalty). After the sell, you will receive an amount of {sellPrice? parseFloat(sellPrice) * (1 - royalty / 100) : 0} $USDC</p>
              </div>
              <button className='btn-main lead mb-5' onClick={addListing} disabled={sellPrice ? false : true}>Sell My NFT</button>
            </div>
          </div>
        )}
        <Dialog
          open={showTransferNft}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className='alert-modal'
        >
          <div className='checkout'>
            <div className='maincheckout'>
              <button
                className='btn-close'
                onClick={() => setShowTransferNft(false)}
              >
                x
              </button>
              <div className='heading'>
                <h3>{t("Manual Transfer")}</h3>
              </div>
              <p>
                <span>Please insert the wallet address you which to transfer this NFT to.</span>
                <span> Make sure this wallet address is compatible with AVAX C-CHAIN.</span>
                <span> Earniverse does not take any responsibility for any wrong transfer executed that will not be subject to refund.</span>
              </p>
              <span className='bold'>Enter wallet address.</span>
              {<FormControl className={classes.formControl}>
                <Autocomplete
                  id="free-solo-demo"
                  freeSolo
                  disablePortal
                  value={transferAddress}
                  getOptionLabel={(option)=>{
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                      return option;
                    }
                    if (!option) {
                      return '';
                    }
                    return option.metamask;
                  }}
                  options={transferAddressList}
                  onInputChange={(event, newInputValue) => {
                    if(transferAddressList.find(obj => {return obj.metamask === newInputValue})) {
                      setSendClicked(false);
                      setTransferAddress(transferAddressList.find(obj => {return obj.metamask === newInputValue}));
                    }
                    setSearchAddress(newInputValue);
                  }}
                  onChange={(event, value, reason) => {
                    if(reason === "clear") {
                      setTransferAddress(0);
                      setTransferAddressList([]);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params}
                      margin="normal"
                      placeholder="Wallet Address"
                      className="form-control"
                      style={{width: '100%'}}
                      onChange={(e) => {
                        handleSearchAddress(e.target.value)
                      }}
                      value={searchAddress}
                    />
                  )}
                  style={{margin: 'auto', width: '100%'}}
                />
                {isSendClicked && transferAddress === 0 && <p style={{color: 'red'}}>Please select address.</p>}
                {transferAddress?.metamask && searchAddress && <>
                  <div className='heading'>
                    <p>Name: </p>
                    <div className='subtotal'>{transferAddress?.username}</div>
                  </div>
                </>}
              </FormControl>}
              <div className={classes.transferButtonBlock}>
                <button
                    className="btn-main"
                    style={{margin: 10}}
                    disabled={!transferAddress?.metamask || !searchAddress}
                    onClick={()=>{if(transferAddress===0) setSendClicked(true); else sendManualTransfer();}}
                >
                    {t("Send")} 
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </React.Fragment>
  );
}

export default ParcelDetails;
