import Web3 from "web3";
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

import LoadingOverlay from 'react-loading-overlay-ts';
import { ReactNotifications } from 'react-notifications-component';
import { useParams } from 'react-router';
import 'react-notifications-component/dist/theme.css';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Footer from '../../components/footer';
import CouponAbi from "../../../../artifacts/contracts/MarketplaceCoupon.sol/MarketplaceCoupon.json";
import * as selectors from '../../../../store/selectors';

import { convertDateFormat } from '../../../../utils/functions/convertDateFormat';
import * as TransactionService from "../../../../services/TransactionService";
import Header from "../../menu/header";


const theme = 'GREY'; //LIGHT, GREY, RETRO

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#30166e',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
  }))(TableCell);
  
const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
    greenField: {
        color: 'green'
    },
    redField: {
        color: 'red'
    },
    transferButtonBlock: {
      display: "flex",
      marginTop: 10,
  },
});

function CouponDetail() {
    const { id } = useParams();
    const { t } = useTranslation();

    const web3 = new Web3(new Web3.providers.HttpProvider(process.env.REACT_APP_AVALANCHE_URL));
    const couponAddress = process.env.REACT_APP_COUPON_CONTRACT;
    const couponContract = new web3.eth.Contract(CouponAbi.abi, couponAddress);

    const [isActive, setActive] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const [loadingMsg, setLoadingMsg] = useState('')
    const signedUser = useSelector(selectors.user);
    const classes = useStyles();

    const txTypes = ['ALL', 'BUY', 'SALE', 'CLAIM', 'MANUAL', 'COMMISSION', 'ROYALTY', 'AUTHOR ROYALTY', 'INFLUENCER', 'CONTACT'];
    const profitTypes = ['SALE', 'COMMISSION', 'ROYALTY', 'AUTHOR ROYALTY', 'INFLUENCER', 'CONTACT'];
    

    useEffect(() => {
        TransactionService.fetchCouponTransaction(id).then(res => {
            setTransactions(res)
        })
        // eslint-disable-next-line
    }, [id]);

    return (
        <div>
            <ReactNotifications />
            <Header className="" />
            <section className='jumbotron breadcumb no-bg' style={{backgroundImage: `url(${'../../img/background/subheader.jpg'})`}}>
                <div className='mainbreadcumb'>
                    <div className='container'>
                        <div className='row m-10-hor'>
                            <div className='col-12'>
                                <h1 className='text-center'>{t("CouponDetail")}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <LoadingOverlay
                active={isActive}
                spinner
                text={loadingMsg}
                className='centeredOverlay'>
                <section className="container">
                    <React.Fragment>
                        <TableContainer component={Paper} style={{maxHeight: 500}}>
                            <Table className={classes.table} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>#</StyledTableCell>
                                        <StyledTableCell>Type of Tx</StyledTableCell>
                                        <StyledTableCell>Tx Detail</StyledTableCell>
                                        <StyledTableCell>Price (USDC)</StyledTableCell>
                                        <StyledTableCell>USDT</StyledTableCell>
                                        <StyledTableCell>Commission</StyledTableCell>
                                        <StyledTableCell>Royalty (USDC)</StyledTableCell>
                                        <StyledTableCell>Author Royalty (USDC)</StyledTableCell>
                                        <StyledTableCell>Influencer (USDC)</StyledTableCell>
                                        <StyledTableCell>Contact (USDC)</StyledTableCell>
                                        <StyledTableCell>USDC Total</StyledTableCell>
                                        <StyledTableCell>From</StyledTableCell>
                                        {/* <StyledTableCell>To</StyledTableCell> */}
                                        <StyledTableCell>Date</StyledTableCell>
                                        <StyledTableCell>Tx</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {transactions.map((tx, index) => {
                                    return (
                                    <TableRow key={index}>
                                        <StyledTableCell>{transactions.length - index}</StyledTableCell>
                                        <StyledTableCell className={profitTypes.includes(tx.typeOfTx) ? classes.greenField : (!tx.sent && tx.typeOfTx === 'MANUAL' ? classes.greenField : classes.redField)}>{tx.typeOfTx}</StyledTableCell>
                                        <StyledTableCell><a href={tx.link} target='_blank'><u>{tx.categoryWithId}</u></a></StyledTableCell>
                                        <StyledTableCell>{tx.price ? Web3.utils.fromWei(tx.price, 'mwei') : tx.price}</StyledTableCell>
                                        <StyledTableCell>{tx.priceInUSDT ? Web3.utils.fromWei(parseFloat(tx.priceInUSDT).toString(), 'mwei') : tx.priceInUSDT}</StyledTableCell>
                                        <StyledTableCell>{tx.commission ? parseFloat(Web3.utils.fromWei(parseFloat(tx.commission).toString(), 'mwei')).toFixed(2) : 0}</StyledTableCell>
                                        <StyledTableCell>{tx.royalty ? parseFloat(Web3.utils.fromWei(parseFloat(tx.royalty).toString(), 'mwei')).toFixed(2) : 0}</StyledTableCell>
                                        <StyledTableCell>{tx.authorRoyalty ? parseFloat(Web3.utils.fromWei(parseFloat(tx.authorRoyalty).toString(), 'mwei')).toFixed(2) : 0}</StyledTableCell>
                                        <StyledTableCell>{tx.influencer ? parseFloat(Web3.utils.fromWei(parseFloat(tx.influencer).toString(), 'mwei')).toFixed(2) : 0}</StyledTableCell>
                                        <StyledTableCell>{tx.contact ? parseFloat(Web3.utils.fromWei(parseFloat(tx.contact).toString(), 'mwei')).toFixed(2) : 0}</StyledTableCell>
                                        <StyledTableCell className={profitTypes.includes(tx.typeOfTx) ? classes.greenField : (!tx.sent && tx.typeOfTx === 'MANUAL' ? classes.greenField : classes.redField)}>{tx.total ? parseFloat(Web3.utils.fromWei(parseFloat(tx.total).toString(), 'mwei')).toFixed(2) : tx.total}</StyledTableCell>
                                        <StyledTableCell>{tx.sender.username}</StyledTableCell>
                                        {/* <StyledTableCell>{tx.recipient.username}</StyledTableCell> */}
                                        <StyledTableCell>{convertDateFormat(tx.createdAt.replace('T', ' ').replace('.000Z', ''))} (UTC)</StyledTableCell>
                                        <StyledTableCell>{<a href={tx.txLink} target="_blank"><u>{tx.txLink.substring(0, 10) + '...'}</u></a>}</StyledTableCell>
                                    </TableRow>)
                                })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </React.Fragment>
                </section>
            </LoadingOverlay>
            <Footer />
        </div>
    )
}

export default CouponDetail