import React from 'react';
import BACKGROUND from "../../assets/images/NewMapBig.jpg"

import useStyles from "../../styles/components/Map/NewMapSection"
import DefaultButton from '../buttons/defaultButton';
import MainMap from "../../assets/images/NewMapBig.png";

function NewMapSection({download}) {
  const classes=useStyles();

  return <div className={classes.root}> 
    <img src={BACKGROUND} alt={BACKGROUND} className={classes.img} />
    <div style={{marginRight: '45%', marginTop: '50%'}} onClick={() => download(MainMap, 'MainMap')}>
      <DefaultButton color="primary" variant='outlined' text="Download Main Map" fontSize={18} outline="white" />
    </div>
  </div>;
}

export default NewMapSection;
