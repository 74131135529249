import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import LoadingOverlay from 'react-loading-overlay-ts';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import Footer from '../../components/footer';
import * as NftService from "../../../../services/NftService";
import * as TileService from "../../../../services/TileService";
import Notification from "../../../../utils/functions/notification";
import Header from "../../menu/header";

function AdminAction() {
    const { t } = useTranslation();
    const [isActive, setActive] = useState(false);
    const [tokenId, setTokenId] = useState();

    const [missingTokenId, setMissingTokenId] = useState();
    const [metamask, setMetamask] = useState();
    const [coupon, setCoupon] = useState();
    const [isVault, setIsVault] = useState(false);

    const onSubmit = () => {
        setActive(true);
        NftService.burnNFT({
            tokenId: tokenId
        }).then((res) => {
            setActive(false);
            setTokenId('');
            Notification(`This NFT burned successfully!`, true);
        }).catch(error => {
            let msg = (typeof error === 'object') ? error.message : error;
            setActive(false);
            Notification(msg, false);
        })
    }

    const onSubmitCancel = () => {
        setActive(true);
        NftService.cancelNFT({
            tokenId: tokenId
        }).then((res) => {
            setActive(false);
            setTokenId('');
            Notification(`This NFT cancelled successfully!`, true);
        }).catch(error => {
            let msg = (typeof error === 'object') ? error.message : error;
            setActive(false);
            Notification(msg, false);
        })
    }

    const onAddTransaction = () => {
        if(metamask && missingTokenId) {
            setActive(true);
            TileService.addMissingTransaction({
                tokenId: missingTokenId,
                metamask: metamask,
                coupon: coupon,
                isVault: isVault,
            }).then((res) => {
                setActive(false);
                setMissingTokenId('');
                setMetamask('');
                setCoupon('');
                setIsVault('');
                Notification(`Transaction added successfully!`, true);
            }).catch(error => {
                let msg = (typeof error === 'object') ? error.message : error;
                setActive(false);
                Notification(msg, false);
            })
        }
        else {

        }
    }
    
    return (
        <div>
            <ReactNotifications />
            <Header className="" />
            <section className='jumbotron breadcumb no-bg' style={{backgroundImage: `url(${'./img/background/subheader.jpg'})`}}>
                <div className='mainbreadcumb'>
                    <div className='container'>
                        <div className='row m-10-hor'>
                            <div className='col-12'>
                                <h1 className='text-center'>{t("Admin Action")}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <LoadingOverlay
                active={isActive}
                spinner
                text={''}
                className='centeredOverlay'>
                <section className="container">
                    <div className='row mt-4'>
                        <div className='col-2'>
                            <span>Token ID</span>
                            <input type="number" className="form-control" onChange={(event) => setTokenId(event.target.value)} value={tokenId}/>
                        </div>
                        <div className='col-10 d-flex'>
                            <input type="button" className="btn-main" value="Burn Item" style={{marginTop: 25, height: 40}} onClick={() => onSubmit()} />
                            <input type="button" className="btn-main" value="Cancel Item" style={{marginTop: 25, height: 40, marginLeft: 10}} onClick={() => onSubmitCancel()} />
                        </div>
                    </div>
                    <div className='row mt-4'>
                        <div className='col-3'>
                            <span>Token ID</span>
                            <input type="number" className="form-control" onChange={(event) => setMissingTokenId(event.target.value)} value={missingTokenId}/>
                        </div>
                        <div className='col-3'>
                            <span>Buyer Metamask</span>
                            <input type="text" className="form-control" onChange={(event) => setMetamask(event.target.value)} value={metamask}/>
                        </div>
                        <div className='col-3'>
                            <span>Used Coupon code</span>
                            <input type="text" className="form-control" onChange={(event) => setCoupon(event.target.value)} value={coupon}/>
                        </div>
                        <div className='col-3'>
                            <button className="btn-main" style={{marginTop: 25, height: 40}} onClick={() => onAddTransaction()}>Add Transaction</button>
                        </div>
                        <div className='col-3'>
                            <input
                                className='form-check-input'
                                type='checkbox'
                                id='flexCheckDefault'
                                checked={isVault}
                                onChange={() => setIsVault(!isVault)}
                            />
                            <label
                            className='form-check-label-profile'
                            htmlFor='flexCheckDefault'
                            >
                                {"Is use vault?"}
                            </label>
                        </div>
                    </div>
                </section>
            </LoadingOverlay>
            <Footer />
        </div>
    )
}

export default AdminAction