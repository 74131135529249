import React from 'react';
import Footer from '../../pages/MarkePlace/components/footer';
import { StyledHeader } from '../MarkePlace/Styles';
import Header from '../MarkePlace/menu/header';
import NewMapSection from '../../components/Map/NewMapSection';
import ChooseSection from '../../components/Map/ChooseSection';
import { useDispatch } from 'react-redux';
import * as actions from '../../store/actions';

const theme = 'HOME'; //LIGHT, GREY, RETRO

function Map() {
  const dispatch = useDispatch();
  
  const download = (url, name) => {
    dispatch(actions.setLoading(true));
    fetch(url, {
      method: "GET",
      headers: {}
    })
      .then(response => {
        response.arrayBuffer().then(function(buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", name + ".png"); //or any other extension
          document.body.appendChild(link);
          link.click();
          dispatch(actions.setLoading(false));
        });
      })
      .catch(err => {
        dispatch(actions.setLoading(false));
      });
  };

  return <div>
    <StyledHeader theme={theme} />
    <Header className="HOME"/>
      <NewMapSection download={download} />
      <ChooseSection download={download} />
    <Footer/>
  </div>;
}

export default Map;
