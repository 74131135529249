import React from 'react';
import { Container, Grid, Typography } from '@material-ui/core';

import useStyles from "../../styles/components/Map/chooseSection";
import { useHistory } from 'react-router';
import METACITY from "../../assets/images/METACITY2.png";
import METACITYLOGO from "../../assets/images/EARNICITY.png";
import WARLAND from "../../assets/images/WARLAND2.png";
import WARLANDLOGO from "../../assets/images/WAR.png";
import HEROLAND from "../../assets/images/HEROLAND2.png";
import HEROLANDLOGO from "../../assets/images/HERO.png";
import SPORTSLAND from "../../assets/images/SPORTSLAND2.png";
import SPORTSLANDLOGO from "../../assets/images/SPORTS.png";
import MONEYLAND from "../../assets/images/MONEYLAND2.png";
import MONEYLANDLOGO from "../../assets/images/MONEY.png";
import FUNLAND from "../../assets/images/FUNLAND2.png";
import FUNLANDLOGO from "../../assets/images/FUN.png";
import DefaultButton from '../buttons/defaultButton';
import MainMap from "../../assets/images/NewMapBig.png";
import SportsLandMap from "../../assets/images/SportsLand.png";
import WarLandMap from "../../assets/images/WarLand.png";
import FunLandMap from "../../assets/images/FunLand.png";
import HeroLandMap from "../../assets/images/HeroLand.png";
import MoneyLandMap from "../../assets/images/MoneyLand.png";
import EarniCityMap from "../../assets/images/EarniCity2.png";
import { EPATHS } from '../../routes/paths.enum';
import { useDispatch } from 'react-redux';
import * as actions from '../../store/actions';

function ChooseSection({download}) {
  const classes=useStyles();
  const history=useHistory();
  const dispatch = useDispatch();

  return <div className={classes.root}> 
    <div className={classes.blockText}>
      <Typography variant='h1' className={classes.title}>Choose your continent</Typography>
      <Container>
        <Grid container spacing={3} className={classes.cardItem}>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12} className={classes.imageContainer}>
            <img src={METACITY} className={classes.tile}/>
          </Grid>
          <Grid item xl={8} lg={8} md={8} sm={12} xs={12} className={classes.rightBlock}>
            <div>
              <img src={METACITYLOGO} className={classes.logo}/>
              <p className={classes.cardDescription}>100% owned by Earniverse, Earnicity Downtown is available to promote in-house products & services, events, partners, but also NFT / Gaming assets and in-house games.</p>
            </div>
            <div className={classes.buttonGroup}>
              <div style={{marginRight: 10, width: '100%'}} onClick={() => download(EarniCityMap, 'EarniCityMap')}>
                <DefaultButton color="primary" variant='outlined' text="Download Map" fontSize={18}/>
              </div>
              <div style={{visibility: 'hidden', width: '80%'}}>
                <DefaultButton color="primary" variant='outlined' text="Buy Land" fontSize={18} />
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3} className={classes.cardItem}>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12} className={classes.imageContainer}>
            <img src={WARLAND} className={classes.tile}/>
          </Grid>
          <Grid item xl={8} lg={8} md={8} sm={12} xs={12} className={classes.rightBlock}>
            <div>
              <img src={WARLANDLOGO} className={classes.logo}/>
              <p className={classes.cardDescription}>Dedicated to War / Shooting / FPS related games.</p>
            </div>
            <div className={classes.buttonGroup}>
              <div style={{marginRight: 10, width: '100%'}} onClick={() => download(WarLandMap, 'WarLand')}>
                <DefaultButton color="primary" variant='outlined' text="Download Map" fontSize={18}/>
              </div>
              <div style={{marginLeft: 10, width: '80%'}} onClick={() => {dispatch(actions.setContinent(4)); history.push({pathname: EPATHS.LAND});}}>
                <DefaultButton color="primary" variant='outlined' text="Buy Land" fontSize={18}/>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3} className={classes.cardItem}>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12} className={classes.imageContainer}>
            <img src={HEROLAND} className={classes.tile}/>
          </Grid>
          <Grid item xl={8} lg={8} md={8} sm={12} xs={12} className={classes.rightBlock}>
            <div>
              <img src={HEROLANDLOGO} className={classes.logo}/>
              <p className={classes.cardDescription}>Dedicated to MMORPG related games.</p>
            </div>
            <div className={classes.buttonGroup}>
              <div style={{marginRight: 10, width: '100%'}} onClick={() => download(HeroLandMap, 'HeroLand')}>
                <DefaultButton color="primary" variant='outlined' text="Download Map" fontSize={18}/>
              </div>
              <div style={{marginLeft: 10, width: '80%'}} onClick={() => {dispatch(actions.setContinent(3)); history.push({pathname: EPATHS.LAND});}}>
                <DefaultButton color="primary" variant='outlined' text="Buy Land" fontSize={18}/>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3} className={classes.cardItem}>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12} className={classes.imageContainer}>
            <img src={SPORTSLAND} className={classes.tile}/>
          </Grid>
          <Grid item xl={8} lg={8} md={8} sm={12} xs={12} className={classes.rightBlock}>
            <div>
              <img src={SPORTSLANDLOGO} className={classes.logo}/>
              <p className={classes.cardDescription}>SportsLand is dedicated to sports-related games within the Sports industry.</p>
            </div>
            <div className={classes.buttonGroup}>
              <div style={{marginRight: 10, width: '100%'}} onClick={() => download(SportsLandMap, 'SportsLand')}>
                <DefaultButton color="primary" variant='outlined' text="Download Map" fontSize={18}/>
              </div>
              <div style={{marginLeft: 10, width: '80%'}} onClick={() => {dispatch(actions.setContinent(1)); history.push({pathname: EPATHS.LAND});}}>
                <DefaultButton color="primary" variant='outlined' text="Buy Land" fontSize={18}/>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3} className={classes.cardItem}>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12} className={classes.imageContainer}>
            <img src={MONEYLAND} className={classes.tile}/>
          </Grid>
          <Grid item xl={8} lg={8} md={8} sm={12} xs={12} className={classes.rightBlock}>
            <div>
              <img src={MONEYLANDLOGO} className={classes.logo}/>
              <p className={classes.cardDescription}>Dedicated to Casino / Playing Cards related games.</p>
            </div>
            <div className={classes.buttonGroup}>
              <div style={{marginRight: 10, width: '100%'}} onClick={() => download(MoneyLandMap, 'MoneyLand')}>
                <DefaultButton color="primary" variant='outlined' text="Download Map" fontSize={18}/>
              </div>
              <div style={{marginLeft: 10, width: '80%'}} onClick={() => {dispatch(actions.setContinent(5)); history.push({pathname: EPATHS.LAND});}}>
                <DefaultButton color="primary" variant='outlined' text="Buy Land" fontSize={18}/>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3} className={classes.cardItem}>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12} className={classes.imageContainer}>
            <img src={FUNLAND} className={classes.tile}/>
          </Grid>
          <Grid item xl={8} lg={8} md={8} sm={12} xs={12} className={classes.rightBlock}>
            <div>
              <img src={FUNLANDLOGO} className={classes.logo}/>
              <p className={classes.cardDescription}>Dedicated to Family and Kids related games.</p>
            </div>
            <div className={classes.buttonGroup}>
              <div style={{marginRight: 10, width: '100%'}} onClick={() => download(FunLandMap, 'FunLand')}>
                <DefaultButton color="primary" variant='outlined' text="Download Map" fontSize={18}/>
              </div>
              <div style={{marginLeft: 10, width: '80%'}} onClick={() => {dispatch(actions.setContinent(2)); history.push({pathname: EPATHS.LAND});}}>
                <DefaultButton color="primary" variant='outlined' text="Buy Land" fontSize={18}/>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3} className={classes.cardItem}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <div style={{marginTop: 30}} onClick={() => {dispatch(actions.setContinent(0)); history.push({pathname: EPATHS.LAND});}}>
              <DefaultButton color="primary" variant='outlined' text="View all Lands" fontSize={18}/>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  </div>;
}

export default ChooseSection;
