import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import useStyles from "../../styles/components/GetStarted/chooseSection"
import WINDOWS from "../../assets/images/Windows_logo.png";
import MAC from "../../assets/images/logo-apple.png";
import EPIC from "../../assets/images/epic_logo.jpg";
import DefaultButton from '../buttons/defaultButton';
import { Button } from '@material-ui/core';

function ChooseSection() {
  const classes=useStyles();

  return <div className={classes.root}> 
    <div className={classes.blockText}>
        <Typography variant='h1' className={classes.title}>Now Available On</Typography>
        <Grid container spacing={3}>
            <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                <img src={EPIC} alt="Epic Games" className={classes.logo} />
                <br/>
                <a href="https://earniverse.io/download-epic" target='_blank'>
                  <DefaultButton color="primary" variant='outlined' text="Download Earniverse" fontSize={18}/>
                </a>
                <div style={{fontSize: 11, paddingTop: 5, color: 'black'}}><i>*Epic Game account required</i></div>
                <br/>
                <a href="https://wiki.earniverse.io/docs/technical-requirements/" target='_blank'>
                  <Button color="primary" variant='outlined' className={classes.sdkButton}>View Specs</Button>
                </a>
            </Grid>
            {/* <Grid item lg={6} xl={6} md={6} sm={6} xs={12}>
                <img src={MAC} alt="mac" className={classes.logo} />
                <br/>
                <DefaultButton color="primary" variant='outlined' text="Download Mac OS Player"  fontSize={18}/>
                <br/>
                <Button color="primary" variant='outlined' className={classes.sdkButton}>View Specs</Button>
            </Grid> */}
        </Grid>
    </div>
  </div>;
}

export default ChooseSection;
