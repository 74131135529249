import Web3 from "web3";
import React, { useEffect, useState } from "react";
import { BreakpointProvider, setDefaultBreakpoints } from "react-socks";
//import { header } from 'react-bootstrap';
import { Link } from '@reach/router';
import { useTranslation } from "react-i18next";
import useOnclickOutside from "react-cool-onclickoutside";
import LOGO from "../../../assets/images/Logo_EIV_Full_Gradient-Horizon.png"
import LOGO_WHITE from "../../../assets/images/Logo_EIV_Full_White-Horizon1.png"
import { useHistory, useLocation } from 'react-router-dom';
import { EPATHS } from "../../../routes/paths.enum";
import { EROLES } from "../../../utils/constants/roles.enum";
import * as selectors from '../../../store/selectors';
import * as actions from '../../../store/actions';
import * as thunkActions from '../../../store/actions/thunks';
import { useSelector, useDispatch } from 'react-redux';
import api from "../../../core/api";
import { login, logout } from "../../../utils/functions/authenticate";
import ModalNoMetamask from "../../../components/Servererror/modalNoMetamask";
import ModalUELogin from "../../../components/Servererror/modalUELogin";
import erc20Abi from "../../../artifacts/contracts/IERC20.sol/IERC20.json";
import * as AuthService from "../../../services/AuthService";
import SessionTimeoutModal from "../../Modals/SessionTimeoutModal";
import { useAccount, useDisconnect } from 'wagmi';
import { useAppKit } from '@reown/appkit/react';
import cryptoWallet from '../../../assets/images/crypto-wallet.png';

setDefaultBreakpoints([
  { xs: 0 },
  { l: 1199 },
  { xl: 1200 }
]);

const NavLink = props => (
  <Link 
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        className: isCurrent ? 'active' : 'non-active',
      };
    }}
  />
);

const Header = function({ className }) {
    const { t } = useTranslation();
    // eslint-disable-next-line
    const [openMenu, setOpenMenu] = React.useState(false);
    // eslint-disable-next-line
    const [openMenu1, setOpenMenu1] = React.useState(false);
    // const [isTimeout, setTimeout] = React.useState(false);
    const [isShowUELogin, setShowUELogin] = React.useState(false);
    const [isSticky,setIsSticky] = React.useState(false);
    const [openNoWalletModal, setOpenNoWalletModal] = React.useState(false);
    const dispatch = useDispatch();
    const [isWrongNetwork, setWrongNetwork] = React.useState(false);
    const [walletAddress, setWalletAddress] = React.useState();

    const notificationsState = useSelector(selectors.notificationsState);
    const balanceState = useSelector(selectors.balance);
    const signedUser = useSelector(selectors.user);
    const isTimeout = useSelector(selectors.isTimeout);
    const notifications = notificationsState.data ? notificationsState.data : [];
    const history=useHistory();
    const web3 = new Web3(new Web3.providers.HttpProvider(process.env.REACT_APP_AVALANCHE_URL));
    const CHAINID = process.env.REACT_APP_AVAX_CHAIN_ID;
    const { address, isConnecting,  isConnected, isReconnecting } = useAccount();    
    const { disconnect, isPending } = useDisconnect();
    const { open } = useAppKit();

    const companyWalletAddress = process.env.REACT_APP_COMPANY_WALLET_ADDRESS;

    web3?.eth?.getChainId().then(res => {
      if(res !== Number(CHAINID)) setWrongNetwork(true);
    });

    const closeMenu = () => {
      setOpenMenu(false);
    };
    const closeMenu1 = () => {
      setOpenMenu1(false);
    };

    const ref1 = useOnclickOutside(() => {
      closeMenu1();
    });

    const [showmenu, btn_icon] = useState(false);
    const [showpop, btn_icon_pop] = useState(false);
    const [shownot, btn_icon_not] = useState(false);
    const closePop = () => {
      btn_icon_pop(false);
    };
    const closeNot = () => {
      btn_icon_not(false);
    };
    const refpop = useOnclickOutside(() => {
      closePop();
    });
    const refpopnot = useOnclickOutside(() => {
      closeNot();
    });

    useEffect(() => {
      if(web3) {
        window?.ethereum?.on('chainChanged', function(networkId) {
          if(parseInt(networkId) === Number(CHAINID)) {
            setWrongNetwork(false);
          }
          else {
            setWrongNetwork(true);
          }
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [web3]);

    useEffect(() => {
      if(address) {
        setWalletAddress(address);
      }
      if(!signedUser?.metamask && address && window.location.pathname.toLowerCase() !== '/register') {
        login(dispatch, actions.setLoading, actions.setUser, history, address);
      }
    }, [address]);

    useEffect(() => {
      setWalletAddress(address);
      const header = document.getElementById("myHeader");
      const totop = document.getElementById("scroll-to-top");
      const sticky = header.offsetTop;
      const scrollCallBack = window.addEventListener("scroll", () => {
        const offset=window.scrollY;
        if(offset > 20 ) {
          setIsSticky(true);
        }
        else{
          setIsSticky(false);
        }
      
        btn_icon(false);
        if (window.pageYOffset > sticky) {
          header?.classList.add("sticky");
          totop?.classList.add("show");
          // 
          
          
        } else {
          header?.classList.remove("sticky");
          totop?.classList.remove("show");
          // 
        } if (window.pageYOffset > sticky) {
          closeMenu();
        }
      });
      if(localStorage.getItem('_user')) {
        dispatch(thunkActions.setUser(JSON.parse(localStorage.getItem('_user'))));
      }
      return () => {
        window.removeEventListener("scroll", scrollCallBack);  
      };
      // eslint-disable-next-line
    }, []);

    const fetchData = async () => {
      const account = signedUser.metamask;
      const tokenAddress = process.env.REACT_APP_EIV_ADDRESS;
      const contract = new web3.eth.Contract(erc20Abi.abi, tokenAddress);
      const response = await contract.methods.balanceOf(account).call();
      dispatch(actions.setBalance(Web3.utils.fromWei(response)));
      dispatch(thunkActions.fetchNotifications());
    }

    // useEffect(() => {
    //   if(window?.ethereum?._state?.accounts !== null && window?.ethereum?._state?.accounts?.length === 0 && signedUser.accessToken) {
    //     logout(dispatch, thunkActions.setUser, history, disconnect);
    //   }
    //   // eslint-disable-next-line
    // } ,[window?.ethereum?._state?.accounts])

    useEffect(() => {
      if(signedUser?.accessToken && !isTimeout) {
        fetchData();
        const interval = setInterval(() => {
          AuthService.sessionCheck()
            .then(res => {})
            .catch(err => {
              if(err.response?.status == '401') {
                dispatch(actions.setIsTimeout(true));
                clearInterval(interval);
              }
            });
        }, 900000);

        return () => clearInterval(interval);
      }
      // eslint-disable-next-line
    }, [signedUser]);

    const handleRedirectToPath=(path)=>{
        history.push({pathname:path});
    } 

    const handleLogout = () => {
      logout(dispatch, thunkActions.setUser, history, disconnect);
    }

    useEffect(() => {
      if(!isConnected) {
        if(walletAddress) {
          logout(dispatch, thunkActions.setUser, history, disconnect);
        }
      }
    }, [isConnected, isReconnecting])

    const switchNetwork = async () => {
      if (window.ethereum.networkVersion !== Number(process.env.REACT_APP_AVAX_CHAIN_ID).toString()) {
        dispatch(actions.setLoading(true));
        try {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: process.env.REACT_APP_AVAX_CHAIN_ID }]
          });
        } catch (err) {}
        dispatch(actions.setLoading(false));
      }
    }
  

    return (
      <header className={`navbar ${window.location.pathname.toLowerCase() === '/explore' 
      || window.location.pathname.toLowerCase() === '/authors' 
      || window.location.pathname.toLowerCase() === '/land' 
      || window.location.pathname.toLowerCase() === '/collection'
      || window.location.pathname.toLowerCase() === '/mynfts'
      || window.location.pathname.toLowerCase() === '/gain'
      || window.location.pathname.toLowerCase() === '/editprofile'
      || window.location.pathname.toLowerCase() === '/wallet'
      || window.location.pathname.toLowerCase() === '/register'
      || window.location.pathname.toLowerCase() === '/myprofile'
      || window.location.pathname.toLowerCase() === '/notifications'
      || window.location.pathname.toLowerCase() === '/transactions'
      || window.location.pathname.toLowerCase() === '/createoptions'
      || window.location.pathname.toLowerCase() === '/createmultiple'
      || window.location.pathname.toLowerCase() === '/setting'
      || window.location.pathname.toLowerCase() === '/profilesmanagement'
      || window.location.pathname.toLowerCase() === '/in-game-wallet'
      || window.location.pathname.toLowerCase() === '/reset-password'
      || window.location.pathname.toLowerCase() === '/confirm-password'
      || window.location.pathname.toLowerCase() === '/change-verification'
      || window.location.pathname.toLowerCase() === '/admin-action'
      || window.location.pathname.toLowerCase().includes('/author/')
      || window.location.pathname.toLowerCase().includes('/coupon')
      || window.location.pathname.toLowerCase().includes('/collection/')
      ? '' : 'white'} ${className}`} id="myHeader">
     <div className='container'>
       <div className='row w-100-nav'>
          <div className='logo px-0'>
              <div className='navbar-title navbar-item'>
                <NavLink to={EPATHS.HOME} onClick={()=>handleRedirectToPath(EPATHS.HOME)}>
                <img
                    src={isSticky ? LOGO : (className === 'HOME' || window.location.pathname.includes('/nft/') ? LOGO : LOGO_WHITE)}
                    className="img-fluid d-block"
                    alt="#"
                    style={{ width:"14vw",
                      height:"100%",
                      cursor: "pointer"}}
                  />
                  <img
                    src={isSticky ? LOGO : (className === 'HOME' || window.location.pathname.includes('/nft/') ? LOGO : LOGO_WHITE)}
                    className="img-fluid d-3"
                    alt="#"
                     style={{ width:"14vw",
                      height:"100%",
                      cursor: "pointer"}}
                  />
                  <img
                    src={isSticky ? LOGO : (className === 'HOME' || window.location.pathname.includes('/nft/') ? LOGO : LOGO_WHITE)}
                    className="img-fluid d-4"
                    alt="#"
                     style={{ width:"14vw",
                      height:"100%",
                      cursor: "pointer"}}
                  />
                  <img
                     src={isSticky ? LOGO : (className === 'HOME' || window.location.pathname.includes('/nft/') ? LOGO : LOGO_WHITE)}
                    className="img-fluid d-none"
                    alt="#"
                     style={{ width:"14vw",
                      height:"100%",
                      cursor: "pointer"}}
                  />
                </NavLink>
              </div>
          </div>
                 
              <BreakpointProvider>

                  <div className='menu'>
                    <div className='navbar-item'>
                      <NavLink to={EPATHS.HOME} onClick={()=>handleRedirectToPath(EPATHS.HOME)}>
                        <div className="dropdown-custom btn">
                          {t("Home")}
                          <span className='lines'></span>
                        </div>
                      </NavLink>
                    </div>

                    <div className='navbar-item'>
                      <NavLink to={EPATHS.GET_STARTED} onClick={()=>handleRedirectToPath(EPATHS.GET_STARTED)}>
                        <div className="dropdown-custom btn">
                          {t("Get Started")}
                          <span className='lines'></span>
                        </div>
                      </NavLink>
                    </div>
                    
                   {/* End Map */}
                   <div className='navbar-item navigation'>
                      <div ref={ref1}>
                        {
                          // eslint-disable-next-line
                          <a>
                            <div className="dropdown-custom btn" 
                              >
                                
                                {t("Maps")}  
                              
                              <span className='lines'></span>
                            </div>
                          </a>
                        }
                      </div>
                      <div className="navigation-content">
                        {
                          // eslint-disable-next-line
                          <a href={EPATHS.MAP} className="landSubmenu map-content" onClick={()=>handleRedirectToPath(EPATHS.MAP)}>
                            <div className="dropdown-custom btn">
                              Map Details
                              <span className='map-lines lines'></span>
                            </div>
                          </a>
                        }
                        

                        {
                          // eslint-disable-next-line
                          <a href={EPATHS.LAND} className="landSubmenu land-content" onClick={()=>handleRedirectToPath(EPATHS.LAND)}>
                            <div className="dropdown-custom btn">
                              Buy Land
                              <span className='land-lines lines'></span>
                            </div>
                          </a>
                        }

                        {
                          // eslint-disable-next-line
                          <a href={EPATHS.TILE_MAP} className="landSubmenu land-content" onClick={()=>handleRedirectToPath(EPATHS.TILE_MAP)}>
                            <div className="dropdown-custom btn">
                              Web Map
                              <span className='land-lines lines'></span>
                            </div>
                          </a>
                        }
                      </div>
                    </div> 
                    {/* <div className='navbar-item'>
                      <div ref={ref1}>
                        <NavLink to={EPATHS.EXPLORE}  onClick={()=>handleRedirectToPath(EPATHS.EXPLORE)}>
                          <div className="dropdown-custom btn">
                            {t("Explore")}  
                            <span className='lines'></span>
                          </div>
                        </NavLink>
                      </div>
                    </div>
                    <div className='navbar-item'>
                      <NavLink to={EPATHS.AUTHORS}  onClick={()=>handleRedirectToPath(EPATHS.AUTHORS)}>
                        <div className="dropdown-custom btn">
                          {t("Authors")}  
                          <span className='lines'></span>
                        </div>
                      </NavLink>
                    </div> */}
                    {signedUser.accessToken && <div className='navbar-item'>
                      <NavLink to={EPATHS.MY_NFTS}  onClick={()=>handleRedirectToPath(EPATHS.MY_NFTS)}>
                        <div className="dropdown-custom btn">
                          My NFT  
                          <span className='lines'></span>
                        </div>
                      </NavLink>
                    </div>}
                    {signedUser.accessToken && signedUser.roleId !== EROLES.STANDARD && <div className='navbar-item'>
                      <NavLink to={EPATHS.MY_COLLECTIONS}  onClick={()=>handleRedirectToPath(EPATHS.MY_COLLECTIONS)}>
                        <div className="dropdown-custom btn">
                          My Collections
                          <span className='lines'></span>
                        </div>
                      </NavLink>
                    </div>}
                    {signedUser.accessToken && signedUser.roleId == EROLES.ADMIN && 
                    <div className='navbar-item'>
                      <NavLink to={EPATHS.SETTINGS}  onClick={()=>handleRedirectToPath(EPATHS.SETTINGS)}>
                        <div className="dropdown-custom btn">
                          Settings
                          <span className='lines'></span>
                        </div></NavLink>
                    </div>}
                    <div className='navbar-item'>
                      <NavLink to={EPATHS.USDC_AVAX} onClick={()=>handleRedirectToPath(EPATHS.USDC_AVAX)}>
                        <div className="dropdown-custom btn">
                          {t("USDC-AVAX")}
                          <span className='lines'></span>
                        </div>
                      </NavLink>
                    </div>
                  </div>
              </BreakpointProvider>

              <div className='mainside'>
                <div style={{display:"flex"}}>
              {!signedUser.accessToken && !((isReconnecting || isConnecting) && localStorage.getItem('_user')) &&
                  <div className='connect-wal' style={{position: 'relative'}}>
                    {isWrongNetwork && <span className="wrong-network" style={{position: 'absolute', left: -138, top: -5}}onClick={() => switchNetwork()}>Wrong Network</span>}
                    {!address && <a className="header-button" onClick={()=>{
                      open();
                    }} >{t("ConnectWallet")}</a>}
                    {address && !signedUser.accessToken && window.location.pathname.toLowerCase() === '/register' && <a className="header-button" onClick={()=>{
                      disconnect();
                    }} >{"Disconnect"}</a>}
                  </div>}
                  {/* {!address && !isPending && <w3m-button />} */}
                </div>
               {signedUser.accessToken && !isReconnecting && !isConnecting && !isPending &&
               <div className="" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                { 
                  <>{isWrongNetwork ? <span className="wrong-network"  style={{marginRight: 10}} onClick={() => switchNetwork()}>Wrong Network</span> : <>{signedUser.roleId === EROLES.ADMIN && <div className="header-button" ><NavLink to={EPATHS.CREATE_OPTIONS} style={{color: 'white', paddingTop: 5, paddingBottom: 5}} onClick={()=>handleRedirectToPath(EPATHS.CREATE_OPTIONS)}>{t("Add 3D Asset")}</NavLink></div>}</>}</>
                }
                  <div id="de-click-menu-notification" className="de-menu-notification" onClick={() => btn_icon_not(!shownot)} ref={refpopnot}>
                      {notifications.length > 0 && <div className="d-count">{notifications.length}</div>}
                      <i className="fa fa-bell"></i>
                      {shownot && 
                        <div className="popshow">
                          <div className="de-flex" onClick={() => history.push({pathname: EPATHS.NOTIFICATIONS})}>
                              <span className="btn-main notification-button">View Notifications</span>
                          </div>
                          <ul>
                            {notifications && notifications.map((notification, index)  => {
                              return (
                                <li key={index}>
                                  <div className="mainnot">
                                      <i class="fa fa-bell"></i>
                                      <div className="d-desc" onClick={() => window.location = notification.url}>
                                          <span className="d-name">{notification.desc.length > 50 ? notification.desc.substring(0, 50) + "..." : notification.desc}</span>
                                      </div>
                                  </div>  
                                </li>
                              )
                            })}
                        </ul>
                        </div>
                        }
                  </div>
                  {address && isConnected && <img src={cryptoWallet} style={{width: 40, marginTop: -5, marginRight: 5, marginLeft: 10, cursor: 'pointer'}} onClick={() => open()}/>}
                  <div id="de-click-menu-profile" className="de-menu-profile" onClick={() => btn_icon_pop(!showpop)} ref={refpop}>
                      <img src={signedUser.accessToken ? (signedUser.picture? `${api.baseUrl}/${signedUser.picture}` : "../../img/author/author-11.png") : ''} alt=""/>
                      {showpop && 
                        <div className="popshow">
                          <div className="d-name">
                              <h4>{signedUser.accessToken ? (signedUser.firstName) : ''}{" "}{signedUser.accessToken ? (signedUser.lastName) : ''}</h4>
                          </div>
                          {/* <div className="d-balance">
                              <h4>{t("Balance")}</h4>
                              { balanceState + ' EIV' }
                          </div> */}
                          <div className="d-line"></div>
                          <ul className="de-submenu-profile">
                            <li  onClick={()=>handleRedirectToPath(EPATHS.MY_PROFILE)}>
                              <NavLink to={EPATHS.MY_PROFILE} style={{}}>
                                <span>
                                  <i className="fa fa-user"></i> {t("MyProfile")}
                                </span>
                              </NavLink>
                            </li>
                            {/* <li  onClick={()=>handleRedirectToPath(EPATHS.EDIT_PROFILE)}>
                              <a href={EPATHS.EDIT_PROFILE} style={{}}>
                                <span>
                                  <i className="fa fa-pencil"></i> {t("EditProfile")}
                                </span>
                              </a>
                            </li> */}
                            {/* <li  onClick={()=>handleRedirectToPath(EPATHS.IN_GAME_WALLET)}>
                              <span>
                                <i className="fa fa-gamepad"></i> {t("In-Game Wallet")}
                              </span>
                            </li> */}
                            {/* <li  onClick={()=>setShowUELogin(true)}>
                              <span>
                                <i className="fa fa-sign-in"></i> {t("UE Login")}
                              </span>
                            </li> */}
                            {(signedUser.metamask.toLowerCase() === companyWalletAddress.toLowerCase() || signedUser.isSnapshot) && <li  onClick={()=>handleRedirectToPath(EPATHS.GAIN)}>
                              <NavLink to={EPATHS.GAIN} style={{}}>
                                <span>
                                  <i className="fa fa-exchange"></i> {t("MyGains")}
                                </span>
                              </NavLink>
                            </li>}
                            {(signedUser.metamask.toLowerCase() === companyWalletAddress.toLowerCase()) && <li  onClick={()=>handleRedirectToPath(EPATHS.ADMIN_ACTION)}>
                              <NavLink to={EPATHS.ADMIN_ACTION} style={{}}>
                                <span>
                                  <i className="fa fa-cog"></i> {t("Admin Actions")}
                                </span>
                              </NavLink>
                            </li>}
                            {signedUser.accessToken && signedUser.metamask.toLowerCase() === companyWalletAddress.toLowerCase() && <li onClick={()=>handleRedirectToPath(EPATHS.COUPON)}>
                              <NavLink to={EPATHS.COUPON} style={{}}>
                                <span>
                                  <i className="fa fa-gift"></i> {t("MyCoupons")}
                                </span>
                              </NavLink>
                            </li>}
                            <li  onClick={()=>handleRedirectToPath(EPATHS.TRANSACTIONS)}>
                              <NavLink to={EPATHS.TRANSACTIONS} style={{}}>
                                <span>
                                  <i className="fa fa-money"></i> {t("Transactions")}
                                </span>
                              </NavLink>
                            </li>
                            {/* {signedUser.accessToken && signedUser.roleId === EROLES.ADMIN && 
                            <li onClick={()=>handleRedirectToPath(EPATHS.PROFILE_MANAGEMENT)}>
                              <span>
                                <i className="fa fa-users"></i> {t("ProfileManagement")}
                              </span>
                            </li>} */}
                            <li onClick={() => handleLogout()}>
                              <span>
                                <i className="fa fa-sign-out"></i> {t("SignOut")}
                              </span>
                            </li>
                          </ul>
                        </div>
                      }
                  </div>
                </div>}
                {(isReconnecting || isConnecting) && localStorage.getItem('_user') && <a className="header-button-disabled">{"Connecting"}</a>}
              </div>
                  
      </div>

        <button className="nav-icon" onClick={() => btn_icon(!showmenu)}>
          <div className="menu-line white"></div>
          <div className="menu-line1 white"></div>
          <div className="menu-line2 white"></div>
        </button>
      </div>
      <ModalNoMetamask open={openNoWalletModal} onClose={() => setOpenNoWalletModal(false)} />
      <ModalUELogin open={isShowUELogin} user={signedUser} onClose={() => setShowUELogin(false)} />
      <SessionTimeoutModal open={isTimeout} />
    </header>
    );
}
export default Header;