import React from 'react';
import { createAppKit } from '@reown/appkit/react'

import { WagmiProvider } from 'wagmi'
import { avalanche, avalancheFuji } from '@reown/appkit/networks'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { WagmiAdapter } from '@reown/appkit-adapter-wagmi'

const queryClient = new QueryClient()

const projectId = 'ae6fb9becb28842f5fee24787f4cf47e'

const metadata = {
  name: 'Earniverse',
  description: 'Earniverse',
  url: 'http://localhost:3000', // origin must match your domain & subdomain
  icons: ['https://avatars.githubusercontent.com/u/179229932']
};

// 3. Set the networks
const networks = [process.env.REACT_APP_PROD_ENV === 'staging' ? avalancheFuji : avalanche];


// 4. Create Wagmi Adapter
const wagmiAdapter = new WagmiAdapter({
  networks,
  projectId,
  ssr: true
});

// 5. Create modal
createAppKit({
  featuredWalletIds: [
    'fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa',
    'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
    '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
  ],
  includeWalletIds: [
    'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
    'fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa'
  ],
  excludeWalletIds: [
    '92ebfc08f0ac3bc8015a9bf843f9366750d5139b00a166086ad893aeb701acd4', //"WalletConnect"
    // '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0' //"Trust Wallet"
  ],
  allWallets: 'HIDE',
  adapters: [wagmiAdapter],
  networks,
  projectId,
  metadata,
  enableWalletConnect: false,
  features: {
    analytics: true, // Optional - defaults to your Cloud configuration
    email: true,
    socials: []
  }
})

const AppKitProvider = ({ children }) => {
  return (
    <WagmiProvider config={wagmiAdapter.wagmiConfig}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  )
}

export default AppKitProvider;