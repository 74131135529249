import { axiosWithToken, axiosWithoutEncryption } from '../utils/functions/apiCallWithToken';

const serverURL = process.env.REACT_APP_SERVER;

export const signout = async () => {
    const result = await axiosWithToken(`${serverURL}/auth/signout`, 'post', {});
    return result;
}

export const signup = async (data) => {
    const result = await axiosWithoutEncryption(`${serverURL}/auth/signup`, 'post', data);
    return result;
}

export const connectWallet = async (data) => {
    const result = await axiosWithoutEncryption(`${serverURL}/auth/connect-wallet`, 'put', data);
    return result;
}

export const signin = async (data) => {
    const result = await axiosWithToken(`${serverURL}/auth/signin`, 'post', data);
    return result;
}

export const verify = async (data) => {
    const result = await axiosWithToken(`${serverURL}/auth/verify`, 'post', data);
    return result;
}

export const resend = async (data) => {
    const result = await axiosWithToken(`${serverURL}/auth/resend`, 'post', data);
    return result;
}

export const fetchUsers = async (params) => {
    const result = await axiosWithToken(`${serverURL}/auth/users/`, 'get', {}, params);
    return result;
}

export const fetchCurrentUser = async (id) => {
    const result = await axiosWithToken(`${serverURL}/auth/user/${id}`, 'get', {});
    return result;
}

export const fetchUsersByAddress = async (params) => {
    const result = await axiosWithToken(`${serverURL}/auth/user/`, 'get', {}, params);
    return result;
}

export const updateUser = async (id, data) => {
    const result = await axiosWithoutEncryption(`${serverURL}/auth/user/update/${id}`, 'put', data);
    return result;
}

export const resetPassword = async (data) => {
    const result = await axiosWithToken(`${serverURL}/auth/reset-password`, 'post', data);
    return result;
}

export const confirmPassword = async (data) => {
    const result = await axiosWithToken(`${serverURL}/auth/confirm-password`, 'post', data);
    return result;
}

export const sessionCheck = async () => {
    const result = await axiosWithToken(`${serverURL}/auth/session-check`, 'get', {});
    return result;
}