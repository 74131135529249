import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import "./assets/styles/animated.css";
import '../node_modules/font-awesome/css/font-awesome.min.css'; 
import '../node_modules/elegant-icons/style.css';
import '../node_modules/et-line/style.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.js';
import './assets/styles/style.scss';
import './assets/styles/style_grey.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import store from './store';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import AppKitProvider from './AppKitProvider';

// Initialize languages
import './locales/i18n';

ReactDOM.render(
  <AppKitProvider>
    <Provider store={store}>
      <HelmetProvider>
        <BrowserRouter>
            <App />
        </BrowserRouter>
      </HelmetProvider>
    </Provider>
  </AppKitProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
